import { Asset } from './asset';
import { LocationFilter } from './location.filter';
import { Notification, OTObject } from './object';

export class Location extends OTObject {
  children: Location[] = [];

  get Parent(): Location {
    const parent = this.breadcrumbs[this.breadcrumbs.length - 1];
    if (!parent) return null;
    return parent;
  }

  get IsEquipment(): boolean {
    return this.layerID === 8; // layerID = 8 is equipment
  }

  get IsAboveEquipment(): boolean {
    return (
      this.nextLayer === 'h' ||
      this.children.filter((item) => item.isAsset).length > 0
    );
  }

  get NextLayerIsAboveEquipment(): boolean {
    return (
      !!this.children &&
      this.children.filter((item) => item.IsAboveEquipment).length > 0
    );
  }

  get AssociatedEquipments(): Location[] {
    if (this.NextLayerIsAboveEquipment) return [...this.children];
    if (this.IsAboveEquipment) return [this];
    return this.children
      .map((item: Location) => item.AssociatedEquipments)
      .flat(2);
  }

  get Assets(): Asset[] {
    if (this.IsEquipment && this.numSensors > 0)
      return [new Asset(this, this.breadcrumbs)];
    return this.children.map((item: Location) => item.Assets).flat(2);
  }

  get NumSensors(): number {
    return this.Assets.map((asset) => asset.numSensors).reduce(
      (a, b) => a + b,
      0
    );
  }

  get Critical(): Asset[] {
    if (this.IsEquipment)
      return this.critical && !this.inactive && !this.isArchived // && !this.warning
        ? [new Asset(this, this.breadcrumbs)]
        : [];
    return this.children.map((item) => item.Critical).flat(2);
  }

  get Warning(): Asset[] {
    if (this.IsEquipment)
      return !this.critical &&
        this.warning &&
        !this.inactive &&
        !this.isArchived
        ? [new Asset(this, this.breadcrumbs)]
        : [];
    return this.children.map((item) => item.Warning).flat(2);
  }

  get Archived(): Asset[] {
    if (this.IsEquipment)
      return this.isArchived ? [new Asset(this, this.breadcrumbs)] : [];
    return this.children.map((item) => item.Archived).flat(2);
  }

  get AssetsWithoutSensors(): Asset[] {
    return this.Assets.filter(
      (asset) => !asset.isArchived && asset.numSensors === 0
    );
  }

  get Working(): Asset[] {
    if (this.IsEquipment)
      return !this.critical &&
        !this.warning &&
        !this.inactive &&
        !this.isArchived &&
        this.NumSensors > 0
        ? [new Asset(this, this.breadcrumbs)]
        : [];
    return this.children.map((item) => item.Working).flat(2);
  }

  get InActive(): Asset[] {
    if (this.IsEquipment)
      return this.inactive && !this.isArchived
        ? [new Asset(this, this.breadcrumbs)]
        : [];
    return this.children.map((item) => item.InActive).flat(2);
  }

  get MACs(): string {
    return !!this.macs
      ? this.macs
          .split(',')
          .map((source: string) => source['replaceAll'](':', ''))
          .filter((source: string) => source.length === 12)
          .reduce((a, b) => a + ',' + b, '')
      : null;
  }

  get GatewayIDs(): string {
    return !!this.gatewayIDs
      ? this.gatewayIDs
          .split(',')
          .map((source: string) => source['replaceAll'](':', ''))
          .reduce((a, b) => a + ',' + b, '')
      : null;
  }

  constructor(
    obj: OTObject,
    nav: OTObject[],
    public breadcrumbs: Location[] = [],
    public filtered: boolean = false,
    public searchTarget: boolean = false
  ) {
    super();
    Object.assign(this, obj);

    if (!!nav) {
      const collator = new Intl.Collator(undefined, {
        numeric: true,
        sensitivity: 'base',
      });
      const sortFn = (a: OTObject, b: OTObject) => {
        // sort display order DESC and object Name ASC
        if (a.displayOrder !== b.displayOrder)
          return b.displayOrder - a.displayOrder;
        return collator.compare(a.objectName, b.objectName);
      };
      this.children = nav
        .filter((item) => item.parentID === this.objectID)
        .sort(sortFn)
        .map(
          (item: OTObject) => new Location(item, nav, [...breadcrumbs, this])
        )
        .sort(sortFn);
      
      if (!this.geoPosition) {
        // if we have no geo position, we take the first one we can find from a child element
        const temp = this.children.find((item) => !!item.geoPosition);
        if (!!temp) this.geoPosition = temp.geoPosition;
      }
    }
  }

  public ApplySearch(searchTerm: string): Location {
    if (
      !searchTerm ||
      this.objectName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (!!this.edgeDeviceIDs &&
        this.edgeDeviceIDs.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (!!this.sensorDigitIDs &&
        this.sensorDigitIDs.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (!!this.MACs &&
        this.MACs.toLowerCase().includes(
          searchTerm['replaceAll'](':', '').toLowerCase()
        )) ||
      (!!this.GatewayIDs &&
        this.GatewayIDs.toLowerCase().includes(
          searchTerm['replaceAll'](':', '').toLowerCase()
        )) || (!!this.objectID &&
          this.objectID.toString().includes(searchTerm))
    ) {
      this.searchTarget = !!searchTerm;
      return Object.assign(
        new Location(this, null, this.breadcrumbs, false, !!searchTerm),
        {
          ...this,
        }
      );
    } else {
      this.searchTarget = false;
    }

    if (this.isAsset) return null;

    const _children = this.children
      .map((item: Location) => item.ApplySearch(searchTerm))
      .filter((item) => !!item);

    if (_children.length > 0) {
      return Object.assign(new Location(this, null, this.breadcrumbs), {
        ...this,
        children: _children,
        filtered: true,
      });
    } else {
      return null;
    }
  }

  public ApplyNotificationFilter(notifications: Notification[]) {
    return this;
    if (
      this.isAsset &&
      !notifications.find((item) => item.objectID === this.objectID)
    ) {
      return null;
    }

    const _children = this.children
      .map((item: Location) => item.ApplyNotificationFilter(notifications))
      .filter((item) => !!item)
      .filter((item) => item.Assets.length > 0);

    if (_children.length > 0 || this.isAsset) {
      return Object.assign(new Location(this, [], []), {
        ...this,
        children: _children,
      });
    } else {
      return null;
    }
  }

  public ApplyFilter(filter: LocationFilter): Location {
    if (
      !!filter.location &&
      !!filter.location.length &&
      filter.location[0] !== this.objectID
    ) {
      return null;
    }

    const childFilter = Object.assign(new LocationFilter(filter), {
      location: [...filter.location].splice(1),
    });
    const _children = this.children
      .filter(
        (item: Location) =>
          !item.isAsset ||
          ((filter.selectedTypes.length === 0 ||
            (filter.selectedTypes.includes(
              item.equipmentType.toLocaleLowerCase()
            ) &&
              !item.isArchived)) &&
            (!filter.warning ||
              (item.warning &&
                !item.inactive &&
                !item.critical &&
                !item.isArchived)) &&
            (!filter.inactive || (item.inactive && !item.isArchived)) &&
            (!filter.critical ||
              (item.critical && !item.inactive && !item.isArchived)) &&
            (!filter.working ||
              (!item.inactive &&
                !item.critical &&
                !item.warning &&
                !item.isArchived)) &&
            (!filter.customer ||
              (item.customerName === filter.customer && !item.isArchived)))
      )
      .map((item: Location) => item.ApplyFilter(childFilter))
      .filter((item) => !!item)
      .filter((item) => item.Assets.length > 0);

    if (_children.length > 0 || this.isAsset) {
      return Object.assign(new Location(this, [], []), {
        ...this,
        children: _children,
        filtered:
          !filter.IsOnlyFilteringCustomer && // if we only filter by customer, we can display the whole plants
          (filter.IsFiltering || this.filtered),
      });
    } else {
      return null;
    }
  }

  public find(id: number): OTObject {
    if (this.objectID === id) return this;
    return this.children
      .map((building: Location) => building.find(id))
      .find((item: OTObject) => !!item && item.objectID === id);
  }
  // same as above with other return type
  public findLocation(id: number): Location {
    if (this.objectID === id) return this;
    return this.children
      .map((building: Location) => building.findLocation(id))
      .find((item: Location) => !!item && item.objectID === id);
  }
}