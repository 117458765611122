import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { I18nService } from '../../../../services/i18n.service';
import { UiService } from '../../../../services/ui.service';
import { SettingsService } from './../../../../services/settings.service';

export class TechnicalDocument {
  public docType: string;
  public language: string;
  public url: string;
}

@Component({
  selector: 'app-technical-documents-subpage',
  templateUrl: './technical-documents-subpage.component.html',
  styleUrls: ['./technical-documents-subpage.component.scss'],
})
export class TechnicalDocumentsSubpageComponent
  implements OnInit, AfterViewInit
{
  @Input() page: string = '';

  get AvailableDocuments(): TechnicalDocument[] {
    return this.page === 'flange'
      ? this.availableDocuments_FL
      : this.page === 'steamtrap'
      ? this.availableDocuments_ST
      : [];
  }

  public availableDocuments_FL: TechnicalDocument[] = [
    {
      docType: 'product',
      language: 'en',
      url: 'https://dm.henkel-dam.com/is/content/henkel/LOCTITE-Pulse-Smart-Flange-PDS',
    },
    {
      docType: 'manual',
      language: 'en',
      url: 'https://dm.henkel-dam.com/is/content/henkel/LOCTITE-Pulse-Smart-Flange-Operating-Manual',
    },
  ];
  public availableDocuments_ST: TechnicalDocument[] = [
    {
      docType: 'product',
      language: 'en',
      url: 'https://dm.henkel-dam.com/is/content/henkel/LOCTITE-Pulse-Smart-Steam-Trap-PDS-Version',
    },
    {
      docType: 'manual',
      language: 'en',
      url: 'https://dm.henkel-dam.com/is/content/henkel/LOCTITE-Pulse-Smart-Steam-Trap-Operating-Manual',
    },
  ];

  public availableDocTypes: string[] = [];
  public availableLanguages: any = {};
  public selectedLanguages: any = {};

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  constructor(
    public ui: UiService,
    private settingsService: SettingsService, // todo: preselect language based on language in user setting
    public i18n: I18nService,
    public route: ActivatedRoute,
    public router: Router
  ) {}

  ngOnInit() {
    this.availableDocTypes = [
      ...this.availableDocuments_FL,
      ...this.availableDocuments_ST,
    ].map((d) => d.docType);
    this.availableDocTypes = this.availableDocTypes.filter(
      (n, i) => this.availableDocTypes.indexOf(n) === i
    ); // distinct docTypes

    this.availableDocTypes.forEach((docType) => {
      const languages = [
        ...this.availableDocuments_FL,
        ...this.availableDocuments_ST,
      ]
        .filter((d) => d.docType === docType)
        .map((d) => d.language);

      this.availableLanguages[docType] = languages.filter(
        (n, i) => languages.indexOf(n) === i
      ); // distinct languages

      this.selectedLanguages[docType] = this.availableLanguages[docType][0];
    });

    this.ui.onNavBack(null);
    this.ui.setBackText(this.i18n.string('settings'));
  }

  ngAfterViewInit(): void {
    this.ui.updateTabIndex(this.route.snapshot.data['tabIndex']);
    this.ui.updateBackBtn(this.route.snapshot.data['backBtn']);
  }

  openDocument(docType: string) {
    const document = this.AvailableDocuments.find((d) => d.docType === docType);
    window.open(document.url, '_blank');
  }

  toFlangeDocuments() {
    this.router.navigate(['settings', 'technical_documents', 'flange']);
  }
  toSteamtrapDocuments() {
    this.router.navigate(['settings', 'technical_documents', 'steamtrap']);
  }
}
