export const I18N_STRINGS = {
  // header:
  home: { de: 'Navigation', en: 'Navigation' },
  asset: { de: 'Asset', en: 'Asset' },
  plant: { de: 'Anlage', en: 'Plant' },
  // login page:
  create_account: { de: 'Ein Konto erstellen', en: 'Create an account' },
  create_account_info: {
    de: 'Füllen Sie das folgende Formular aus, um Ihr Konto zu erstellen und Ihnen Zugang zu Loctite Pulse zu geben',
    en: 'Fill out the following form to check and approve your account and give you access to Loctite Pulse',
  },
  username: { de: 'Benutzername', en: 'Username' },
  password: { de: 'Passwort', en: 'Password' },
  email: { de: 'Email', en: 'Email' },
  login_failed: {
    de: 'Login-Daten sind falsch.',
    en: 'Login details are wrong.',
  },
  acc_blocked: {
    de: 'Dieser Account ist aktuell blockiert. \n Bitte kontaktieren Sie service@loctite-pulse.com, um ihren Account wieder freizuschalten.',
    en: 'The user account is currently blocked. \n Please contact service@loctite-pulse.com to have your account unblocked.',
  },
  login: { de: 'Anmeldung', en: 'Login' },
  enter_loctite: { de: 'Anmelden', en: 'Enter Loctite Pulse' },
  via_portal: { de: 'Über Portal anmelden', en: 'Login via Portal' },
  msal_login: { de: 'MS Entra Anmeldung', en: 'MS Entra Login' },
  skip_login: { de: 'Anmeldung überspringen', en: 'Skip Login' },
  sign_up: {
    de: 'Noch keinen Account? Hier anmelden',
    en: 'No account yet? Sign up here',
  },
  already_account: {
    de: 'Ich habe bereits ein Konto, Log In',
    en: 'I already have an account, Log In',
  },
  go_to_ia: { de: 'Zum Installation Agent', en: 'Go to Installation Agent' },
  forgot_password_button: {
    de: 'Passwort vergessen?',
    en: 'Forgot your password?',
  },
  send_mail: {
    de: 'Email versenden',
    en: 'Send email',
  },
  forgot_password_title: { de: 'Passwort vergessen?', en: 'Forgot password?' },
  forgot_password_text: {
    de: 'Bitte geben Sie ihre Email-Adresse an und wir werden Ihnen Anweisungen zum Ändern Ihres Passworts zusenden.',
    en: "Enter your email address and we'll send you an email with instructions on how to set a new password.",
  },
  back_to_login: { de: 'Zurück zur Anmeldung', en: 'Back to login' },
  check_your_email: {
    de: 'Überprüfen Sie Ihre E-Mail',
    en: 'Check your email',
  },
  check_your_email_generic: {
    de: 'Falls die von Ihnen eingegebene E-Mail-Adresse mit einem Konto auf unserer Plattform verbunden ist, haben wir Ihnen eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts geschickt. Bitte prüfen Sie Ihren Posteingang und Ihren Spam-Ordner auf eine E-Mail von uns.',
    en: "If the email address you entered is associated with an account on our platform, we've sent you an email with instructions on how to reset your password. Please check your inbox and spam folder for an email from us.",
  },
  check_your_email1: {
    de: 'Wir haben Ihnen eine E-Mail geschickt an ',
    en: 'We have sent you an email to ',
  },
  check_your_email2: {
    de: '. Bitte klicken Sie auf den Link, um ein neues Passwort zu wählen.',
    en: '. Please click on the link to choose a new password.',
  },
  email_not_received: {
    de: 'Ich habe keine Email erhalten.',
    en: "I haven't received an email.",
  },
  pw_changed_success: {
    de: 'Ihr Passwort wurde erfolgreich geändert, Sie können sich jetzt anmelden.',
    en: 'Your password has been successfully changed, you can now login.',
  },
  signed_up_success: {
    de: 'Anmeldeformular erfolgreich abgeschickt.',
    en: 'Registration form successfully submitted.',
  },
  unknown_mail: {
    de: 'Unbekannte E-Mail Adresse',
    en: 'Unknown email address',
  },
  mail_send_error: {
    de: 'E-Mail konnte nicht versendet werden. Bitte versuchen Sie es später erneut.',
    en: 'Failed to send email. Please try again later.',
  },
  // sign up:
  choose_password: { de: 'Wählen Sie Ihr Passwort', en: 'Choose password' },
  repeat_password: { de: 'Passwort wiederholen', en: 'Repeat password' },
  country: {
    de: 'Land, das Loctite Pulse Dienstleistungen erhält',
    en: 'Country to receive Loctite Pulse Services',
  },
  select_country: { de: 'Land auswählen', en: 'Select Country' },
  select_customer: { de: 'Kunde auswählen', en: 'Select Customer' },
  customerentity: { de: 'Kundenunternehmen', en: 'Customer Entity' },
  customer: { de: 'Kunde', en: 'Customer' },
  submit_form: { de: 'Formular abschicken', en: 'Submit form' },
  signup_failed: { de: 'Registrierung fehlgeschlagen', en: 'Signup failed' },
  //system maintenance
  system_maintenance: { de: 'System maintenance', en: 'System maintenance' },
  maintenance_message: {
    de: 'We are doing maintenance on Loctite Pulse, some features may not be available or look the same.',
    en: 'We are doing maintenance on Loctite Pulse, some features may not be available or look the same.',
  },
  system_outage: { de: 'Systemausfall', en: 'System downtime' },
  outage_message: {
    de: 'Aufgrund eines Ausfalls, ist diese App nicht vollständig zugänglich und es können nicht alle Daten aktualisiert werden. Wir entschuldigen uns für die Unannehmlichkeiten.\n\nBitte haben Sie Geduld mit uns, während wir mit höchster Priorität daran arbeiten, den Service wieder online zu bringen. Wir danken Ihnen für Ihre Geduld und Ihr Verständnis.',
    en: 'Due to a current outage, this app is not completely accessible and not all data can be updated. We apologize for the inconvenience.\n\nPlease bear with us as we work with highest priority to bring the service back online. Thank you for your patience and understanding.',
  },
  understood: { de: 'Understood', en: 'Understood' },
  // admin approval:
  approve_user: { de: 'Benutzerkonto freigeben', en: 'Approve user account' },
  approve_user_info: {
    de: 'Ein Benutzer hat sich für ein neues Benutzerkonto registriert. Bitte prüfen Sie die nachstehenden Informationen und gewähren oder verweigern Sie den Zugang zu Loctite Pulse.',
    en: 'A user has registered for a new user account. Please review the information below and grant or deny access to Loctite Pulse.',
  },
  grant_access: {
    de: 'Zugriff gewähren',
    en: 'Grant access',
  },
  deny_access: {
    de: 'Zugriff verweigern',
    en: 'Deny access',
  },
  access_granted_success: {
    de: 'Dem Nutzer wurde Zugriff zu LOCTITE Pulse gewährt.',
    en: 'The user has been granted access to LOCTITE Pulse.',
  },
  access_denied_success: {
    de: 'Dem Nutzer wurde Zugriff zu LOCTITE Pulse verweigert.',
    en: 'The user has been denied access to LOCTITE Pulse.',
  },
  loading_approval_data_failed: {
    de: 'Beim Laden der Benutzerregistrierungsdaten ist ein Fehler aufgetreten. Die Freigabe wurde möglicherweise bereits bearbeitet oder ist abgelaufen. Bitte beachten Sie, dass Links zur Benutzerfreigabe nur 14 Tage lang gültig sind.',
    en: 'An error occurred while loading the user registration data. The approval may have already been processed or may have expired. Please note that user approval links are only valid for 14 days.',
  },
  registration_processing_failed: {
    de: 'Es ist ein Fehler beim konfigurieren des Zugangs aufgetreten.',
    en: 'An error occurred while configuring the access.',
  },
  // change pw:
  current_password: { de: 'Aktuelles Passwort', en: 'Current password' },
  new_password_1: { de: 'Neues Passwort', en: 'New password' },
  new_password_2: {
    de: 'Neues Passwort wiederholen',
    en: 'Repeat new password',
  },
  change_password_submit: {
    de: 'Neues Passwort festlegen',
    en: 'Set new password',
  },
  change_password_failed: {
    de: 'Passwortänderung fehlgeschlagen.',
    en: 'Password change failed.',
  },
  change_password_unauthorized: {
    de: 'Dieser Link ist abgelaufen. Bitte fordern Sie einen neuen Link an, um Ihr Passwort zu ändern.',
    en: 'This link has expired. Please request a new link to change your password.',
  },
  resend_mail: {
    de: 'E-Mail mit neuem Link anfordern.',
    en: 'Request email with new link.',
  },
  passwords_must_be_equal: {
    de: 'Passwörter müssen identisch sein',
    en: 'Passwords must be identical',
  },
  pw_validation1: {
    de: 'Mindestens 12 Zeichen',
    en: 'At least 12 characters',
  },
  pw_validation2: {
    de: 'Groß- und Kleinbuchstaben',
    en: 'Mix of uppercase and lowercase letters',
  },
  pw_validation3: {
    de: 'Mindestens eine Ziffer',
    en: 'At least one digit',
  },
  pw_validation4: {
    de: 'Mindestens ein Sonderzeichen',
    en: 'At least one special character',
  },
  pw_validation5: {
    de: 'Passwörter stimmen nicht überein',
    en: 'Passwords do not match',
  },
  pw_validation6: {
    de: 'Bestätigen Sie Ihr Passwort',
    en: 'Confirm your password',
  },
  email_validation1: {
    de: 'E-Mail Adresse ist erforderlich',
    en: 'Email address is required',
  },
  email_validation2: {
    de: 'E-Mail-Adresse ist ungültig',
    en: 'Email address is not valid',
  },
  email_validation3: {
    de: 'Diese E-Mail-Adresse wird bereits verwendet',
    en: 'Email address is already in use',
  },
  customer_required: {
    de: 'Auswahl ist erforderlich',
    en: 'Selection is required',
  },
  name_validation: {
    de: 'Vorname ist erforderlich',
    en: 'Name is required',
  },
  surname_validation: {
    de: 'Nachname ist erforderlich',
    en: 'Surname is required',
  },
  length_validation: {
    de: 'Mindestens 3 Zeichen',
    en: 'At least 3 characters',
  },
  // header:
  navigation: { de: 'Navigation', en: 'Navigation' },
  notifications: { de: 'Benachrichtigungen', en: 'Notifications' },
  settings: { de: 'Weitere Informationen', en: 'More information' },
  // home page
  map: { de: 'Karte', en: 'Map' },
  map_panel: {
    de: 'Standort / Gebäude / Equipment',
    en: 'Plants / Buildings / Equipments',
  },
  list: { de: 'Liste', en: 'List' },
  satellite_view: { de: 'Satellitenansicht', en: 'Satellite View' },
  map_view: { de: 'Kartenansicht', en: 'Map View' },
  // filter:
  search: { de: 'Suche', en: 'Search' },
  filter: { de: 'Filter', en: 'Filter' },
  filters: { de: 'Filter', en: 'Filters' },
  find_equipment: { de: 'Finde Geräte', en: 'Find equipments' },
  show_results: { de: 'Ergebnisse anzeigen', en: 'Show results' },
  clear_search: { de: 'Suche löschen', en: 'Clear search' },
  clear_filter: { de: 'Filter löschen', en: 'Clear filters' },
  status: { de: 'Status', en: 'Status' },
  all_status: { de: 'Jeder Status', en: 'All status' },
  working: { de: 'Funktionsfähig', en: 'Working' },
  warning: { de: 'Warnung', en: 'Warning' },
  critical: { de: 'Kritisch', en: 'Critical' },
  inactive: { de: 'Inaktiv', en: 'Inactive' },
  equipment_type: { de: 'Equipment-Typ', en: 'Equipment Type' },
  flange: { de: 'Flansch', en: 'Flange' },
  tank: { de: 'Tank', en: 'Tank' },
  pipe: { de: 'Rohrleitung', en: 'Pipe' },
  valve: { de: 'Ventil', en: 'Valve' },
  steamtrap: { de: 'Kondensatableiter', en: 'Steam Trap' },
  rotatingEqu: { de: 'Rotating Equ.', en: 'Rotating Equ.' },
  steamtrap_non_abbrev: { de: 'Kondensat-Ableiter', en: 'Steam Trap' },
  all_customers: { de: 'Alle Kunden ausgewählt', en: 'All customers selected' },
  location: { de: 'Standort', en: 'Location' },
  find_assets: { de: 'Finde Geräte', en: 'Find equipment' },
  // plant list:
  equipment_found: { de: 'Gerät gefunden.', en: 'equipment found' },
  equipments_found: { de: 'Geräte gefunden.', en: 'equipments found' },
  no_equipments_found: {
    de: 'Keine Geräte gefunden',
    en: 'No equipments found',
  },
  no_equipments_found2: {
    de: 'Bitte Suche und Filter löschen.',
    en: 'Please clear your search and filters.',
  },
  // card component:
  critical_equipment: { de: 'kritisches Gerät', en: 'critical equipment' },
  critical_equipments: { de: 'kritische Geräte', en: 'critical equipments' },
  show_critical_equipments: {
    de: 'Kritische Geräte anzeigen',
    en: 'Show critical equipments',
  },
  warning_equipment: { de: 'Warnung', en: 'warning' },
  warning_equipments: { de: 'Warnungen', en: 'warnings' },
  show_warning_equipments: {
    de: 'Geräte mit Warnungen anzeigen',
    en: 'Show warning equipments ',
  },
  in_active_equipment: { de: 'inaktives Gerät', en: 'in-active equipment' },
  in_active_equipments: { de: 'inaktive Geräte', en: 'in-active equipments' },
  show_inactive_sensors: {
    de: 'In-active Sensoren anzeigen',
    en: 'Show in-active sensors',
  },
  in_active_sensor: { de: 'inaktiver Sensor', en: 'in-active sensor' },
  in_active_sensors: { de: 'inaktive Sensoren', en: 'in-active sensors' },
  in_active: { de: 'inaktiv', en: 'in-active' },
  other_equipment_working_fine: {
    de: 'anderes Gerät funktionsfähig',
    en: 'other equipment working fine',
  },
  other_equipments_working_fine: {
    de: 'andere Geräte funktionsfähig',
    en: 'other equipments working fine',
  },
  all_equipments_working_fine: {
    de: 'Alle Geräte funktionsfähig',
    en: 'All equipments working fine',
  },
  see_whole_plant: { de: 'Zur ganzen Anlage', en: 'See whole plant' },
  show_inactive_equipments: {
    de: 'De-installierte Sensordaten anzeigen',
    en: 'Show de-installed equipment',
  },
  show_archive: {
    de: 'Archiv',
    en: 'Archive',
  },
  // asset overview:
  back_to: { de: 'Zurück zu ', en: 'Back to ' },
  back: {
    de: 'Zurück',
    en: 'Back',
  },
  sensor_status: { de: 'Sensorstatus', en: 'Sensor status' },
  health_status: { de: 'Funktionstüchtigkeit', en: 'Health Status' },
  remaining_useful_life: {
    de: 'Verbleibende Nutzungsdauer',
    en: 'Remaining useful life',
  },
  more_details: { de: 'Mehr Informationen', en: 'More details' },
  connection_status: { de: 'Connection Status', en: 'Connection Status' },
  Find_sensor_ques: {
    de: 'Wie findet man den Sensor?',
    en: 'How to find the sensor?',
  },
  Find_sensor_position: {
    de: 'Die Sensoren befinden sich entlang der Rohrabschnitte. Je niedriger die letzten drei Ziffern des Abschnittes sind, desto näher befindet sich der Sensor am Edge Device (ED).',
    en: 'The sensors are located along the pipe sections. The lower the last three digits of the section are, the closer the sensor is to the Edge Device (ED).',
  },
  equipment_status: { de: 'Status des Equipments', en: 'Equipment Status' },
  refresh_and_update: { de: 'Aktualisieren', en: 'Refresh and update' },
  show_sensor_health: { de: 'EDGE DEVICE-STATUS', en: 'EDGE DEVICE HEALTH' },
  edge_device_health: { de: 'Edge Device Status', en: 'Edge Device health' },
  please_check: { de: 'Bitte überprüfen', en: 'Please check' },
  working_fine: { de: 'Funktioniert einwandfrei', en: 'Working fine' },
  no_data: { de: 'Keine Daten', en: 'No data' },
  ago1: { de: 'Zeit seit letzter Messung: ', en: '' },
  ago2: { de: '', en: ' ago' },
  never_checked: { de: 'Nie geprüft', en: 'Never checked' },
  asset_not_found: {
    de: 'Gerät nicht gefunden :(',
    en: 'Equipment not found :(',
  },
  days: {
    de: 'Tage',
    en: 'Days',
  },
  weeks: {
    de: 'Wochen',
    en: 'Weeks',
  },
  months: {
    de: 'Monate',
    en: 'Months',
  },
  temperature_of_the_edge_device: {
    de: 'Temperatur des Edge Device',
    en: 'Temperature of the edge device',
  },
  steam_trap_monitoring: {
    de: 'Kondensatableiter-Überwachung',
    en: 'Steam Trap Monitoring',
  },
  high_heat_resistance: {
    de: 'mit Sensor mit hoher Hitzebeständigkeit',
    en: 'with high heat resistance sensor',
  },
  steam_trap_states: {
    de: 'Kondensatableiter Zustände %',
    en: 'Steam Trap States %',
  },
  steam_trap_health: {
    de: 'Kondensatableiter Zustand',
    en: 'Steam Trap Health',
  },
  steam_trap_working: {
    de: 'In Ordnung',
    en: 'Working',
  },
  steam_trap_blow_through: {
    de: 'Durchstrahlen',
    en: 'Blow through',
  },
  steam_trap_blocked: {
    de: 'Blockiert',
    en: 'Blocked',
  },
  inactive_status: {
    de: 'Inaktiv',
    en: 'Inactive',
  },
  steam_loss: {
    de: 'Dampfverlust',
    en: 'Steam Loss',
  },
  steam_loss_overview: {
    de: 'Dampfverlust-Übersicht',
    en: 'Steam Loss Overview',
  },
  steam_loss_overview_content: {
    de: 'Dies ist die Gesamtübersicht der Dampfverluste.',
    en: 'This is the overall steam loss overview.',
  },
  steam_loss_prediction: {
    de: 'Vorhersage des Dampfverlustes',
    en: 'Steam Loss Prediction',
  },
  steam_loss_prediction_content: {
    de: 'Höhe des Dampfverlustes bei einer Fehlfunktion des Kondensatableiters.',
    en: 'Amount of steam loss in case of malfunctioning steam trap.',
  },
  in_the_next: {
    de: 'in den nächsten ',
    en: 'in the next ',
  },
  week_1: {
    de: 'Woche',
    en: '1 week',
  },
  month_1: {
    de: 'Monat',
    en: '1 month',
  },
  months_3: {
    de: 'drei Monaten',
    en: '3 months',
  },
  inlet: {
    de: 'Einlass',
    en: 'Inlet',
  },
  outlet: {
    de: 'Auslass',
    en: 'Outlet',
  },
  mean_inlet: {
    de: 'Einlass',
    en: 'Inlet',
  },
  mean_outlet: {
    de: 'Auslass',
    en: 'Outlet',
  },
  min: {
    de: 'Min',
    en: 'Min',
  },
  max: {
    de: 'Max',
    en: 'Max',
  },

  //rotating equipment
  rul_bold_content: {
    de: 'Die Restnutzungsdauer (RUL)',
    en: 'Remaining useful life (RUL)',
  },

  rul_content: {
    de: ' ist die geschätzte Zeit, die ein rotierendes Gerät effektiv arbeiten kann, bevor es ersetzt werden muss oder unzuverlässig wird. Sie ist entscheidend für die Wartungsplanung und die Minimierung von Ausfallzeiten.',
    en: ' is the estimated time a rotating equipment can operate effectively before needing replacement or becoming unreliable. Its vital for maintenance planning and minimizing downtime.',
  },

  rul_image: {
    de: '% der Nutzungsdauer des Geräts verbraucht.',
    en: '% vitality of the equipment',
  },

  // repair and replace:
  back_to_equipment_status: {
    de: 'Zum Status des Equipments',
    en: 'Back to equipment status',
  },
  repair_equipment: {
    de: 'Equipment reparieren',
    en: 'Repair equipment',
  },
  replace_sensor: {
    de: 'Sensor ersetzen',
    en: 'Replace sensor',
  },
  repair_overview: {
    de: 'Reparaturübersicht',
    en: 'Repair overview',
  },
  step_by_step_instructions: {
    de: 'Schritt-für-Schritt-Anleitung',
    en: 'Step by step instructions',
  },
  connect_new_sensor: {
    de: 'Neuen Sensor verbinden',
    en: 'Connect new sensor',
  },
  // notifications:
  notification_found: {
    de: 'Benachrichtigung gefunden.',
    en: 'notification found.',
  },
  notifications_found: {
    de: 'Benachrichtigungen gefunden.',
    en: 'notifications found.',
  },
  no_notifications_found: {
    de: 'Keine Benachrichtigungen gefunden.',
    en: 'No notifications found.',
  },
  problem_detected: {
    de: 'PROBLEM GEFUNDEN',
    en: 'PROBLEM DETECTED',
  },
  equipment: {
    de: 'Equipment',
    en: 'Equipment',
  },
  equipment_short: {
    de: 'Equip.',
    en: 'Equip',
  },
  edge_device: {
    de: 'Edge Device',
    en: 'Edge Device',
  },
  edge_device_short: {
    de: 'Hardware',
    en: 'Hardware',
  },
  repaired: {
    de: 'REPARIERT',
    en: 'REPAIRED',
  },
  review_equipment_status: {
    de: 'Gerätzustand überprüfen',
    en: 'Review equipment status',
  },
  sensor_instructions: {
    de: 'Sensor-Anweisungen',
    en: 'Sensor instructions',
  },
  add_documentation: {
    de: 'Dokumentation hinzufügen',
    en: 'Add documentation',
  },
  add_documentation_success: {
    de: 'Documentation erfolgreich hinzugefügt',
    en: 'Documentation successfully added',
  },
  measured_value: {
    de: 'Gemessener Wert: ',
    en: 'Measured Data: ',
  },
  increased_value: {
    de: 'Wertanstieg: ',
    en: 'Increased Value: ',
  },
  repair_and_replace: {
    de: 'Reparieren und ersetzen',
    en: 'Repair and replace',
  },
  // settings:
  language: { de: 'Sprache', en: 'Language' },
  change_password: { de: 'Passwort ändern', en: 'Change password' },
  measurement_system: {
    de: 'Einheitensystem & Format',
    en: 'Measurement system & format',
  },
  help_and_support: { de: 'Hilfe und Support', en: 'Help and Support' },
  imprint: {
    de: 'Nutzungsbedingungen, Datenschutz und Impressum',
    en: 'Terms, privacy and imprint',
  },
  logout: { de: 'Abmelden', en: 'Logout' },
  profile: { de: 'Benutzerprofil', en: 'User profile' },
  profile_photo: { de: 'Profilfoto', en: 'Profile photo' },
  personal_information: { de: 'Persönliche Daten', en: 'Personal information' },
  save_changes: { de: 'Änderungen speichern', en: 'Save changes' },
  apply_changes: { de: 'Änderungen anwenden', en: 'Apply changes' },
  appearance: { de: 'Aussehen', en: 'Appearance' },
  save_information: { de: 'Informationen speichern', en: 'Save information' },
  changes_successful: {
    de: 'Die Änderungen wurden erfolgreich umgesetzt.',
    en: 'The changes were implemented successfully.',
  },
  changes_problem: {
    de: 'Es ist ein Problem aufgetreten und die Änderungen konnten nicht umgesetzt werden.',
    en: 'There has been a problem and the changes could not be implemented.',
  },
  system_of_measurement: { de: 'Maßsystem', en: 'System of measurement' },
  date_format: { de: 'Datumsformat', en: 'Date format' },
  allow_push_notification: {
    de: 'Push-Benachrichtigung erlauben',
    en: 'Allow push notification',
  },
  notificationsflange: {
    de: 'Benachrichtigungen Flansch',
    en: 'Flange Notifications',
  },
  notificationspipe: {
    de: 'Benachrichtigungen Rohrleitung',
    en: 'Pipe Notifications',
  },
  notificationssteamtrap: {
    de: 'Benachrichtigungen Kondensatableiter',
    en: 'Steam Trap Notifications',
  },
  push_notification: { de: 'Push-Benachrichtigung', en: 'Push notification' },
  push_notification_abbrev: { de: 'Push-Benachr.', en: 'Push notif.' },
  sms: { de: 'SMS-Benachrichtigung', en: 'SMS notification' },
  sms_abbrev: { de: 'SMS-Benachr.', en: 'SMS notif.' },
  warning_notifications: { de: 'Warnungen', en: 'Warnings' },
  in_active_notification_msg: {
    de: 'Letzte Übertragung am',
    en: 'Last transmission detected on',
  },
  allow_email: { de: 'E-Mails erlauben', en: 'Allow emails' },
  push_notification_modal_text: {
    de: 'Möchten Sie Benachrichtigungen von dieser Seite erhalten?',
    en: 'Would you like to receive notifications from this site?',
  },
  always: {
    de: 'Jedes Mal',
    en: 'Always',
  },
  never: {
    de: 'Nie',
    en: 'Never',
  },
  push_permission_granted: {
    de: 'Berechtigung für Push Benachrichtigungen erteilt.',
    en: 'Permission for push notifications granted.',
  },
  push_permission_denied: {
    de: 'Berechtigung für Push Benachrichtigungen verweigert.',
    en: 'Permission for push notifications denied.',
  },
  email_will_be_sent_to: {
    de: 'E-Mail wird gesendet an ',
    en: 'Email will be sent to ',
  },
  about_loctite: { de: 'Über Loctite', en: 'About Loctite' },
  faq: { de: 'FAQ', en: 'FAQ' },
  your_contact: { de: 'Ihr Kontakt:', en: 'Your contact:' },
  q_change_appearance: {
    de: 'Ist es möglich, das Aussehen der App zu ändern?',
    en: 'Is it possible to change the appareance of the app?',
  },
  q_document_problem: {
    de: 'Wie kann ich ein Problem dokumentieren?',
    en: 'How can I document a problem?',
  },
  q_sensor_health: {
    de: 'Was ist Sensorstatus?',
    en: 'What is sensor health?',
  },
  alerts: {
    de: 'Alarme',
    en: 'Alerts',
  },
  warnings: {
    de: 'Warnungen',
    en: 'Warnings',
  },
  // Technical Documents
  technical_documents: {
    de: 'Technische Dokumente',
    en: 'Technical documents',
  },
  view_technical_documents: {
    de: 'Technische Dokumente ansehen',
    en: 'View technical documents',
  },
  technical_documents_smart_flange: {
    de: 'Technische Dokumente für Smart Flange',
    en: 'Technical documents for Smart Flange',
  },
  technical_documents_steamtrap: {
    de: 'Technische Dokumente für Kondensat-ableiter',
    en: 'Technical documents for Steam Trap',
  },
  doctype_label_product: {
    de: 'Produktdatenblatt (Englisch)',
    en: 'Product Description Sheet',
  },
  select_language: {
    de: 'Sprache auswählen',
    en: 'Select language',
  },
  doctype_label_manual: {
    de: 'Bedienungsanleitung (Englisch)',
    en: 'Manual',
  },
  show_document: {
    de: 'Dokument anzeigen',
    en: 'Show document',
  },
  //cookie consent

  cookie_consent: { de: 'Cookie Consent', en: 'Cookie Consent' },
  cookies_settings: { de: 'Cookies Settings', en: 'Cookies Settings' },
  cookie_consent_first_paragraph: {
    de: 'Sofern Sie eingewilligt haben, benutzt diese Website Adobe Analytics, einen Webanalysedienst der Adobe Systems Software Ireland Limited (4-6 Riverwalk, City West Business Campus, Saggart, Dublin 24, Irland) ("Adobe"). Zu diesem Zweck wird ein Cookie auf Ihrem Endgerät installiert. Dieses Cookie sammelt Daten wie Browser- und Geräteinformationen, Ihre IP-Adresse, besuchte Webseiten sowie Datum und Uhrzeit der Serveranfrage, um Ihr Nutzerverhalten unserer Webseite auszuwerten, um Berichte über die Webseitenaktivität für Webseitenbetreiber zu erstellen und um weitere mit der Webseitennutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen.',
    en: 'Provided you have consented, this website uses Adobe Analytics, a web analytics service provided by Adobe Systems Software Ireland Limited (4-6 Riverwalk, City West Business Campus, Saggart, Dublin 24, Ireland) (“Adobe”). For this purpose, a cookie is installed on your device. This cookie will collect data such as browser and device information, your IP address, visited websites, and date and time of server request for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage to the website provider.',
  },
  cookie_consent_second_paragraph: {
    de: 'Adobe (als für die Verarbeitung Verantwortlicher) kann Ihre Daten für eigene Zwecke wie die Profilerstellung und die Verknüpfung Ihrer Daten mit anderen über Sie erhobenen Daten (z. B. über Ihr Adobe-Konto) nutzen.',
    en: 'Adobe (as a controller) may use your data for any own purposes as for profiling and linking your data to other data collected about you (e.g. through your Adobe Account).',
  },
  cookie_consent_third_paragraph: {
    de: 'Die durch das Cookie erzeugten Informationen über Ihre Webseitennutzung (einschließlich Ihrer IP-Adresse) werden an einen Server von Adobe in das Vereinigte Königreich übertragen und dort verarbeitet. Für diese Datenübermittlung in ein Drittland wird gemäß Art. 46 DSGVO ein angemessenes Datenschutzniveau durch Standardvertragsklauseln sichergestellt, darüber hinaus existiert für das Vereinigte Königreich ein Angemessenheitsbeschluss der Europäischen Kommission (Art. 45 DSGVO).',
    en: 'The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and processed by Adobe on servers in the United Kingdom. For this third country data transfer, an adequate level of data protection is ensured by standard contractual clauses, Art. 46 GDPR, pending an adequacy decision by the European Commission (Art. 45 GDPR).',
  },
  cookie_consent_fourth_paragraph: {
    de: 'Wir haben die IP-Anonymisierung aktiviert, sodass das letzte Oktett (der letzte Teil) der IP-Adresse sofort unkenntlich gemacht wird, wenn die IP-Adresse von Adobe erfasst wird. Diese Anonymisierung wird vor jeder Verarbeitung der IP-Adresse durchgeführt. Dadurch ist lediglich nur ein ungefährer Standort zu statistischen Analysezwecken verfügbar. Nach der Geolokalisierung wird die IP-Adresse durch die generische IP-Adresse ::X.X.X.X. ersetzt.',
    en: 'We have activated the IP anonymization, which means that the last octet (the last portion) of the IP address is immediately hidden when the IP address is collected by Adobe. This anonymization is performed prior to any processing of the IP address. Only an approximate location will be available for the purpose of statistical analysis. After the geo-lookup, everywhere in Analytics the IP addresses are obfuscated – replaced with :: X.X.X.X.',
  },
  cookie_consent_fifth_paragraph: {
    de: 'Indem Sie Cookies auf der Henkel-Webseite zulassen, erklären Sie sich mit der Nutzung der oben genannten Daten und der zuvor beschriebenen Verarbeitung durch Adobe einverstanden.\n Weitere Informationen zum Datenschutz bei Adobe-Diensten finden Sie hier: "https://www.adobe.com/privacy/p"',
    en: 'By allowing cookies on the Henkel-website you agree to the use of the above-mentioned data and the previously described processing by Adobe.\n You find more information about privacy in Adobe services here: "https://www.adobe.com/privacy/policy.html"',
  },
  cookie_consent_sixth_paragraph: {
    de: 'Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie die Cookies auf unserer Webseite unter „Cookies“ bei „Cookie-Einstellungen“ deaktivieren. Wenn Sie mit der Verwendung von Cookies generell einverstanden sind, Sie aber die Verwendung von Adobe Analytics-Cookies verhindern wollen, können Sie diesem Link folgen (https://www.adobe.com/privacy/opt-out.html), um die Erfassung und Verwendung von Daten (Cookies und IP-Adresse) durch Adobe Analytics zu deaktivieren.',
    en: 'You can withdraw your consent at any time with effect for the future by disabling cookies on our website under "Cookies" at "Cookie settings". If you agree to the use of cookies in general, but however, you are uncomfortable with the use of Adobe Analytics cookies, you can follow this link (https://www.adobe.com/privacy/opt-out.html) and deactivate the Adobe Analytics collection and use of data (cookies and IP address).”',
  },
  cookie_consent_subtitle: {
    de: 'Widerruf der Datenerhebung:',
    en: 'Objection to data collection:',
  },
  agree_to: {
    de: 'Ich bin mit den ',
    en: 'I agree to the ',
  },
  service_conditions: {
    de: 'Servicebedingungen',
    en: 'Service Conditions',
  },
  the: {
    de: ' und den ',
    en: ', the ',
  },
  app_terms_use: {
    de: 'Nutzungsbedingungen der App',
    en: 'App Terms of Use',
  },
  and_have_read: {
    de: ' einverstanden und habe die ',
    en: ' and I have read the ',
  },

  // Terms of service text
  terms_of_service: { de: 'Nutzungsbedingungen', en: 'Terms of use' },
  // Privacy policy text
  privacy_policy: { de: 'Datenschutzbestimmungen', en: 'Privacy policy' },
  agree_to_general: {
    de: 'Ich erkläre mich mit den Allgemeinen ',
    en: 'I agree to the General ',
  },
  terms_and_conditon: {
    de: 'Nutzungsbedingungen einverstanden',
    en: 'Terms and Condition of Use',
  },
  have_read: { de: 'Ich habe die Erklärung zum ', en: 'I have read the ' },
  data_protection_decalaration: {
    de: 'Datenschutzerklärung gelesen',
    en: 'Data Protection Declaration',
  },
  cookies: { de: 'Cookies', en: 'Cookies' },
  CAPrivacyRights: { de: 'CA Privacy Rights', en: 'CA Privacy Rights' },
  DSAR: {
    de: 'Do Not Sell or Share My Personal Information',
    en: 'Do Not Sell or Share My Personal Information',
  },
  //scroll to top component
  back_to_top: { de: 'Zurück zum Seitenanfang', en: 'Back to top' },

  // equipment overview:
  additional_information: {
    de: 'Zusätzliche Informationen',
    en: 'Additional Information',
  },
  information_about_equipment: {
    de: 'Informationen zum Gerät',
    en: 'Information about equipment',
  },
  sensor_installation_data: {
    de: 'Sensor-Installationsdaten',
    en: 'Sensor installation data',
  },
  sensor_name: {
    de: 'SENSOR NAME',
    en: 'SENSOR NAME',
  },
  sensor_number: {
    de: 'SENSORNUMMER',
    en: 'SENSOR NUMBER',
  },
  edge_device_number: {
    de: 'EDGEDEVICE-NUMMER',
    en: 'EDGE DEVICE NUMBER',
  },
  inlet_number: {
    de: 'NUMBER AT THE SENSOR INPUT',
    en: 'SENSOR INLET NUMBER',
  },
  outlet_number: {
    de: 'NUMBER AT THE SENSOR OUTPUT',
    en: 'SENSOR OUTLET NUMBER',
  },
  dau_code: {
    de: 'DAU-CODE',
    en: 'DAU CODE',
  },
  gateway_code: {
    de: 'GATEWAY-CODE',
    en: 'GATEWAY CODE',
  },
  junction_box_slot: {
    de: 'JUNCTION BOX',
    en: 'JUNCTION BOX',
  },
  additional_documents: {
    de: 'Zusätzliche Dokumente',
    en: 'Additional documents',
  },
  show: { de: 'Anzeigen', en: 'Show' },
  needs_to_be_repaired: {
    de: 'Muss repariert werden',
    en: 'Needs to be repaired',
  },
  problem_fixed: { de: 'Problem gelöst', en: 'Problem fixed' },
  false_alert: { de: 'Falscher Alarm', en: 'False alert' },
  others: { de: 'Sonstiges', en: 'Others' },
  note: { de: 'Notiz', en: 'Note' },
  photo: { de: 'Foto', en: 'Photo' },
  photos: { de: 'Fotos', en: 'Photos' },
  files: { de: 'Dateien', en: 'Files' },
  sensor_health: { de: 'Sensorstatus', en: 'Sensor health' },
  // sensor health:
  of_the_edge_device: { de: 'des Edge Device', en: 'of the edge device' },
  problem_detected_title: {
    de: 'Problem festgestellt',
    en: 'Problem detected',
  },
  problem_detected_text: {
    de: 'Es wurde ein Problem mit dem Edge-Gerät festgestellt. Bitte ersetzen Sie es bei Bedarf und gleichen Sie alle an dieses Edge-Device angeschlossenen Sensoren ab.',
    en: 'A problem has been detected with the Edge device. Please replace it if necessary and match all sensors connected to this Edge device.',
  },
  replace_edge_device_button: {
    de: 'Edge-Device ersetzen',
    en: 'Replace edge device',
  },
  low_battery_text: {
    de: 'Schwache Batterie, bitte ersetzen Sie das Edge-Device.',
    en: 'Low battery, please replace the edge device.',
  },
  low_signal_text: {
    de: 'Niedrige Signalqualität, bitte überprüfen Sie die Konnektivität des Edge-Device und reparieren oder ersetzen Sie es, falls erforderlich.',
    en: 'Low signal quality, please check the connectivity of the edge device and repair or replace if necessary.',
  },
  tilt_text: {
    de: 'Gerät gekippt, bitte überprüfen Sie das Edge-Device und stellen Sie es in die richtige Position.',
    en: 'Device tilted, please check the edge device and put it in the correct position.',
  },
  tilt_text_warning: {
    de: 'Es scheint, dass das Edge Device verschoben wurde und nicht mehr in aufrechter Position steht. Bitte überprüfen Sie dies bei einem Ihrer nächsten Kontrollgänge.',
    en: 'It seems that the edge device has been moved and is not placed in an upright position any more. Please check in one of your next inspection rounds.',
  },
  tilt_true_message: {
    de: 'Edge-Device gekippt',
    en: 'Edge device tilted',
  },
  tilt_false_message: {
    de: 'Edge-Device in korrekter Position',
    en: 'Device in correct position',
  },
  // telemetry:
  sensor_data: { de: 'Sensordaten', en: 'Sensor Data' },
  history: { de: 'Historie', en: 'History' },
  show_all_entries: { de: 'Alle Einträge anzeigen', en: 'Show all entries' },
  show_details: { de: 'Details anzeigen', en: 'Show details' },
  hide_details: { de: 'Details ausblenden', en: 'Hide details' },
  // documentation wizard:
  pick_something_that_applies: {
    de: 'Wählen Sie etwas aus, das zutrifft',
    en: 'Pick something that applies',
  },
  please_add_more_information: {
    de: 'Bitte fügen Sie weitere Informationen hinzu',
    en: 'Please add more information',
  },
  add_note: { de: 'Notiz hinzufügen', en: 'Add note' },
  save_note: { de: 'Notiz speichern', en: 'Save note' },
  take_or_upload_a_photo: {
    de: 'Foto aufnehmen oder hochladen (optional)',
    en: 'Take or upload a photo (optional)',
  },
  take_a_photo: {
    de: 'Foto aufnehmen (optional)',
    en: 'Take a photo (optional)',
  },
  upload_a_photo: {
    de: 'Foto hochladen (optional)',
    en: 'Upload a photo (optional)',
  },
  add_file: { de: 'Datei hinzufügen (optional)', en: 'Add a file (optional)' },
  save_documentation: {
    de: 'Dokumentation speichern',
    en: 'Save documentation',
  },
  max_size: { de: 'max. Größe: 20MB', en: 'Max-size: 20MB' },
  upload_file: { de: 'Datei hochladen', en: 'Upload a file' },
  upload_another_file: {
    de: 'Eine weitere Datei hochladen',
    en: 'Upload another file',
  },
  // new:
  name: { de: 'Vorname', en: 'Name' },
  surname: { de: 'Nachname', en: 'Surname' },
  designation: { de: 'Berufsbezeichnung', en: 'Job Title' },
  phonenr: { de: 'Handynummer', en: 'Cell phone number' },
  optional: { de: 'optional', en: 'optional' },
  error_loading_data: {
    de: 'Fehler beim Laden der Daten',
    en: 'Error loading data',
  },
  // view building plan
  view_building_plan: { de: 'Gebäudeplan anschauen', en: 'View building plan' },
};
