import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { formatDistanceToNow } from 'date-fns';
import { de, enUS } from 'date-fns/esm/locale';

import { AssetDetail } from '../../model/asset';
import { CurrentTelemetry } from '../../model/sensor';
import { I18nService } from '../../services/i18n.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TileComponent implements OnInit {
  @Input() telemetry: CurrentTelemetry = null;
  @Input() asset: AssetDetail = null;
  @Input() isLeakageSensor: boolean = false;
  @Input() isSteamTrap: boolean = false;
  @Input() isSteamTrapInletOutlet: boolean = false;
  @Input() steamtrap_outlet: CurrentTelemetry = null; // ugly special case for steam traps :(
  @Input() displayStyle: string = null;

  get Title() {
    if (
      !!this.telemetry &&
      !this.telemetry.critical &&
      !this.telemetry.warning &&
      !this.telemetry.inactive &&
      !!this.telemetry.workingMessage
    )
      return this.telemetry.workingMessage;
    if (
      !!this.telemetry &&
      this.telemetry.inactive &&
      !!this.telemetry.inactiveMessage
    )
      return this.telemetry.inactiveMessage;
    if (
      !!this.telemetry &&
      this.telemetry.critical &&
      !!this.telemetry.criticalMessage
    )
      return this.telemetry.criticalMessage;
    if (
      !!this.telemetry &&
      this.telemetry.warning &&
      !!this.telemetry.warningMessage
    )
      return this.telemetry.warningMessage;

    return this.telemetry ? this.telemetry.title : 'telemetry data not found';
  }
  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  get IsRepairAndReplace(): boolean {
    return this.displayStyle === 'repair-and-replace';
  }

  constructor(
    private ui: UiService,
    public router: Router,
    public i18n: I18nService,
    public changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.ui.OnOrientationChange.subscribe(() =>
      this.changeDetectorRef.detectChanges()
    );
  }

  validTimeValue(timestamp) {
    try {
      this.minutesToNow(timestamp);
    } catch (e) {
      return false;
    }
    return true;
  }
  minutesToNow(timestamp) {
    return formatDistanceToNow(timestamp, {
      locale: this.i18n.Lng === 'de' ? de : enUS,
    }).replace('about ', '');
  }

  extraParsing(input) {
    // there's no pretty way to satisfy this weird design request...
    if (input === 'Working') return 'Working fine';
    else return input;
  }
}
