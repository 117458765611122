import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { StorageService } from '../../../../cc-framework/services/storage.service';
import { environment } from '../../../../environments/environment';
import { AssetDetail } from '../../../model/asset';
import { HistoryItem } from '../../../model/history';
import { Location } from '../../../model/location';
import { CurrentTelemetry, Sensor } from '../../../model/sensor';
import { UserSettings } from '../../../model/settings';
import { GraphData, TelemetryHistory } from '../../../model/telemetry_history';
import { AssetsService } from '../../../services/assets.service';
import { I18nService } from '../../../services/i18n.service';
import { SettingsService } from '../../../services/settings.service';
import { UiService } from '../../../services/ui.service';
import { Documentation } from './../../../model/documentation';

@Component({
  selector: 'app-telemetry-page',
  templateUrl: './telemetry-page.component.html',
  styleUrls: ['./telemetry-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TelemetryPageComponent implements OnInit, AfterViewInit {
  public asset: AssetDetail = null;
  public asset4Sidebar: AssetDetail = null; // used so that the sidebar does not 'lose' the measurements which are filtered out in this view
  public sensor: Sensor = null;
  public telemetry: CurrentTelemetry = null;
  public telemetry_history: TelemetryHistory = null;
  public isOfEdgeDevice: boolean = false;
  public telemetry_graphData: GraphData[] = [];
  public loading: boolean = false;
  public telemetry_loading: boolean = false;
  public error: boolean = false;
  public thumbnailLoading;
  public breadcrumbsLoading;
  public overlayDocumentation: boolean = false;
  public historyItems: HistoryItem[] = [];
  public documentationAdded: boolean = false;
  public assetID;
  public sensorID;
  public measurementID;
  public timeframe = null;
  public loadedTimeframe = null;
  public settings: UserSettings;
  private lastTimeframeSelectIndex = 0;
  public plantID = -1;
  public edgeDeviceID = '';

  @ViewChildren('datePick') datePickers: QueryList<any>;

  private range: Date[] = [
    new Date(new Date().setDate(new Date().getDate() - 1)),
    new Date(),
  ];

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  get Title(): string {
    return this.i18n.parse(this.telemetry.title);
  }

  // special cases:
  // "There's nothing more permanent than a temporary solution"

  get IsTilt(): boolean {
    return this.IsSensorHealth && this.telemetry.measurementTypeID === 1034;
  }

  get IsBattery(): boolean {
    return this.IsSensorHealth && this.telemetry.measurementTypeID === 1018;
  }

  get IsSignalQuality(): boolean {
    return this.IsSensorHealth && this.telemetry.measurementTypeID === 1028;
  }

  get DisplayErrorCard(): boolean {
    return (
      (this.IsTilt || this.IsBattery || this.IsSignalQuality) &&
      (this.telemetry.critical || this.telemetry.warning)
    );
  }

  get ErrorCardText(): string {
    if (this.IsTilt && this.telemetry.critical) {
      return this.i18n.string('tilt_text');
    } else if (
      this.IsTilt &&
      this.telemetry.warning &&
      !this.telemetry.critical
    ) {
      return this.i18n.string('tilt_text_warning');
    } else if (this.IsBattery) {
      return this.i18n.string('low_battery_text');
    } else if (this.IsSignalQuality) {
      return this.i18n.string('low_signal_text');
    } else {
      return this.i18n.string('problem_detected_text');
    }
  }

  get ErrorCardHasReplaceButton(): boolean {
    return this.DisplayErrorCard && !this.IsTilt;
  }
  get InstallationAgentURL(): string {
    return (
      environment.installation_agent_url +
      '/login/refauth/' +
      encodeURIComponent(this.storage.Token) +
      '/' +
      encodeURIComponent(
        btoa(
          JSON.stringify({
            targetUrl:
              'plant/edge-device/' + this.plantID + '/' + this.edgeDeviceID,
          })
        )
      )
    );
  }
  get Timeframe(): {
    start: Date;
    end: Date;
    custom: boolean;
  } {
    return this.timeframe;
  }

  set Timeframe(timeframe: { start: Date; end: Date; custom: boolean }) {
    if (
      !timeframe ||
      !timeframe.start ||
      !timeframe.end ||
      !isValidDate(timeframe.start) ||
      !isValidDate(timeframe.end)
    )
      timeframe = { start: new Date(), end: new Date(), custom: false };
    this.timeframe = timeframe;
    let reloadData = false;
    if (this.error) reloadData = true;
    if (!this.loadedTimeframe) {
      this.loadedTimeframe = timeframe;
      reloadData = true;
    } else {
    }
    if (timeframe.start < this.loadedTimeframe.start) {
      this.loadedTimeframe.start = timeframe.start;
      reloadData = true;
    }
    if (timeframe.end > this.loadedTimeframe.end) {
      this.loadedTimeframe.end = timeframe.end;
      reloadData = true;
    }
    if (reloadData) this.refresh();
    this.changeRef.detectChanges();
  }

  get Breadcrumbs(): Location[] {
    return [...this.asset.breadcrumbs, this.asset];
  }

  get IsSensorHealth(): boolean {
    return (
      this.isOfEdgeDevice &&
      !this.telemetry.title.toLowerCase().includes('temperature')
    );
  }

  get Range(): Date[] {
    return this.range;
  }

  set Range(range: Date[]) {
    this.range = range;
  }

  constructor(
    private assetsService: AssetsService,
    private route: ActivatedRoute,
    public router: Router,
    public ui: UiService,
    public changeRef: ChangeDetectorRef,
    public i18n: I18nService,
    public settingsService: SettingsService,
    public storage: StorageService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((result: any) => {
      this.assetID = +result.params['id'];
      this.sensorID = +result.params['sensorid'];
      this.measurementID = +result.params['measurementid'];
      this.refresh(true);
    });

    this.ui.onNavBack(() => {
      this.goBack();
    });

    this.ui.OnOrientationChange.subscribe(() => {
      this.changeRef.detectChanges();
    });
  }

  ngAfterViewInit(): void {
    this.ui.updateTabIndex(this.route.snapshot.data['tabIndex']);
    this.ui.updateBackBtn(this.route.snapshot.data['backBtn']);
  }

  get VisibleTelemetryGraphs(): GraphData[] {
    return this.telemetry_graphData.filter(
      (data: GraphData) => data.metadata.displayGraph
    );
  }

  refresh(disableCache = false) {
    this.documentationAdded = false;
    this.changeRef.detectChanges();
    if (!this.telemetry_graphData.length || disableCache) {
      // this only applies when initially loading page, not when reloading more data
      this.loading = true;
      this.changeRef.detectChanges();

      this.settingsService
        .getUserSettings()
        .subscribe((settings: UserSettings) => {
          this.settings = settings;

          this.assetsService
            .getAsset(this.assetID, disableCache, settings.MeasurementSystem)
            .subscribe(
              (result: {
                asset: AssetDetail;
                plant: Location;
                thumbnailLoading: boolean;
                breadcrumbsLoading: boolean;
              }) => {
                this.thumbnailLoading = result.thumbnailLoading;
                this.breadcrumbsLoading = result.breadcrumbsLoading;
                this.asset4Sidebar = Object.assign(
                  new AssetDetail(result.asset, result.asset.breadcrumbs),
                  { ...result.asset }
                ); // copy
                this.asset = Object.assign(
                  new AssetDetail(result.asset, result.asset.breadcrumbs),
                  { ...result.asset }
                ); // copy
                this.ui.setBackText(
                  this.i18n.parse(this.asset.objectName) +
                    (this.IsSensorHealth
                      ? ' | ' + this.i18n.string('sensor_health')
                      : '')
                );
                this.asset.sensors = this.asset.sensors
                  .filter((sensor) => sensor.sensorID === this.sensorID)
                  .map((sensor) => Object.assign(new Sensor(), sensor)) // copy
                  .map((sensor) => {
                    sensor.telemetry = sensor.telemetry.filter(
                      (telemetry) =>
                        telemetry.measurementTypeID === this.measurementID
                    );
                    return sensor;
                  });
                this.plantID = !!result.plant ? result.plant.objectID : -1;
                if (this.asset.sensors.length) {
                  this.sensor = this.asset.sensors.find(
                    (sensor) => sensor.sensorID === this.sensorID
                  );
                  this.edgeDeviceID = this.sensor.edgeDigitID;
                  this.telemetry = [
                    ...this.sensor.telemetry,
                    ...this.asset.health,
                    ...(this.asset.temperature ? [this.asset.temperature] : []),
                  ].find(
                    (telemetry) =>
                      telemetry.measurementTypeID === this.measurementID
                  );
                  if (
                    this.telemetry.title
                      .toLowerCase()
                      .includes('temperature') ||
                    this.telemetry.title.toLowerCase().includes('tilt') ||
                    this.telemetry.title.toLowerCase().includes('battery') ||
                    this.telemetry.title.toLowerCase().includes('signal')
                  ) {
                    this.isOfEdgeDevice = true;
                  }
                }

                this.changeRef.detectChanges();
                if (!!this.loading) {
                  // find default timeframe for loading telemetry (initially)
                  const end =
                    !!this.telemetry &&
                    !!this.telemetry.Timestamp &&
                    isValidDate(this.telemetry.Timestamp)
                      ? this.telemetry.Timestamp
                      : new Date();
                  this.loadedTimeframe = {
                    start: new Date(new Date(end).setDate(end.getDate() - 9)),
                    end: end,
                  };

                  this.changeRef.detectChanges();
                  if (!this.telemetry_loading) {
                    this.telemetry_loading = true;
                    this.assetsService
                      .getTelemetry(
                        this.assetID,
                        this.sensorID,
                        this.measurementID,
                        this.settings.MeasurementSystem,
                        this.loadedTimeframe
                      )
                      .subscribe(
                        (_result: TelemetryHistory) => {
                          this.loading = false;
                          this.telemetry_loading = false;
                          this.telemetry_history = _result;
                          this.telemetry_graphData =
                            this.telemetry_history.GetGraphData();
                          this.onTelemetryTimeframeSelect(0);
                          this.changeRef.detectChanges();
                        },
                        (error) => {
                          this.loading = false;
                          this.error = true;
                          this.changeRef.detectChanges();
                        }
                      );
                  }
                }
              }
            );
        });
    } else {
      this.telemetry_graphData = this.telemetry_graphData.map((data) => {
        data.loading = true;
        return data;
      });
      this.changeRef.detectChanges();

      this.settingsService
        .getUserSettings()
        .subscribe((settings: UserSettings) => {
          this.settings = settings;

          if (!this.telemetry_loading) {
            this.telemetry_loading = true;
            this.changeRef.detectChanges();
            this.assetsService
              .getTelemetry(
                this.assetID,
                this.sensorID,
                this.measurementID,
                this.settings.MeasurementSystem,
                this.loadedTimeframe
              )
              .subscribe((result: TelemetryHistory) => {
                this.telemetry_loading = false;
                this.telemetry_history = result;
                this.telemetry_graphData =
                  this.telemetry_history.GetGraphData();
                this.changeRef.detectChanges();
              });
          } else {
            this.telemetry_graphData = this.telemetry_history.GetGraphData();
            this.changeRef.detectChanges();
          }
        });
    }
  }

  toSensorHistory(sensorID, measurementTypeID) {
    if (!this.historyItems || this.historyItems.length === 0) return;
    if (sensorID && measurementTypeID) {
      this.router.navigate([
        'sensor-history',
        this.asset.objectID,
        sensorID,
        measurementTypeID,
      ]);
    }
  }

  onDocumentationAdded(documentation: Documentation) {
    this.refresh();
    this.documentationAdded = true;
  }

  isTelemetryTimeframeCustom(): boolean {
    return !!this.timeframe && this.timeframe.custom;
  }

  onTelemetryTimeframeSelect(index: number) {
    let firstMeasurement = null;
    let lastMeasurement = null;
    this.lastTimeframeSelectIndex = index;
    if (!this.telemetry_graphData || !this.telemetry_graphData.length) return;
    for (let i = 0; i < this.telemetry_graphData.length; i++) {
      const gdata = this.telemetry_graphData[i];
      if (gdata.telemetry.length > 0) {
        if (!gdata.metadata.firstMeasurement)
          gdata.metadata.lastMeasurement = gdata.telemetry[0].timestamp;
        if (!gdata.metadata.lastMeasurement)
          gdata.metadata.lastMeasurement =
            gdata.telemetry[gdata.telemetry.length].timestamp;
        if (!!gdata) {
          if (
            !firstMeasurement ||
            gdata.metadata.firstMeasurement < firstMeasurement
          ) {
            firstMeasurement = gdata.metadata.firstMeasurement;
          }
          if (
            !lastMeasurement ||
            gdata.metadata.lastMeasurement > lastMeasurement
          ) {
            lastMeasurement = gdata.metadata.lastMeasurement;
          }
        }
      }
    }
    if (!firstMeasurement && !lastMeasurement) return;

    if (!!firstMeasurement)
      firstMeasurement = new Date(Date.parse(firstMeasurement));
    if (!!lastMeasurement)
      lastMeasurement = new Date(Date.parse(lastMeasurement));
    let end: Date = !!lastMeasurement ? lastMeasurement : new Date();
    let start: Date = new Date(new Date(end).setDate(end.getDate() - 7)); // default: 7 days
    switch (index) {
      case 0:
        if (start < firstMeasurement) {
          start = firstMeasurement;
          end = new Date(new Date(start).setDate(start.getDate() + 7));
        }
        this.Timeframe = {
          start: start,
          end: end,
          custom: false,
        };
        this.Range = [this.timeframe.start, this.timeframe.end];
        break;
      case 1:
        start = new Date(new Date(end).setDate(end.getDate() - 35));
        if (start < firstMeasurement) {
          start = firstMeasurement;
          end = new Date(new Date(start).setDate(start.getDate() + 35));
        }
        this.Timeframe = {
          start: start,
          end: end,
          custom: false,
        };
        this.Range = [this.timeframe.start, this.timeframe.end];
        break;
      case 2:
        start = new Date(new Date(end).setMonth(end.getMonth() - 6));
        if (start < firstMeasurement) {
          start = firstMeasurement;
          end = new Date(new Date(start).setMonth(start.getMonth() + 6));
        }
        this.Timeframe = {
          start: start,
          end: end,
          custom: false,
        };
        this.Range = [this.timeframe.start, this.timeframe.end];
        break;
    }
    this.changeRef.detectChanges();
  }

  resetTimeframeSelect() {
    this.onTelemetryTimeframeSelect(this.lastTimeframeSelectIndex);
  }

  onRangeStartPick(measurementTypeID, sensorID, event, endPickerID, data) {
    if (data.openRangePicker === 1) data.openRangePicker = 2;
    data.openRangePicker = 2;
    this.range[0] = event;

    let lastMeasurement = null;
    for (let i = 0; i < this.telemetry_graphData.length; i++) {
      const gdata = this.telemetry_graphData[i];
      if (!!gdata) {
        if (
          !lastMeasurement ||
          gdata.metadata.lastMeasurement > lastMeasurement
        ) {
          lastMeasurement = gdata.metadata.lastMeasurement;
        }
      }
    }
    if (!!lastMeasurement) {
      lastMeasurement = new Date(Date.parse(lastMeasurement));
      if (this.range[1] > lastMeasurement) {
        this.range[1] = lastMeasurement;
      }
    }

    // const end: Date = telemetry.Timestamp;
    if (
      this.range[0].toISOString() !== this.timeframe.start.toISOString() ||
      this.range[1].toISOString() !== this.timeframe.end.toISOString()
    ) {
      this.Timeframe = {
        start: this.range[0],
        end: this.range[1],
        custom: true,
      };
    }

    if (data.openRangePicker === 2) {
      const el: any = document.getElementById(endPickerID);
      if (!!el) el.click();
    }
    this.changeRef.detectChanges();
  }

  onRangeEndPick(measurementTypeID, sensorID, event, data) {
    data.openRangePicker = 0;
    if (event < this.range[0]) {
      this.range[1] = this.range[0];
      this.range[0] = event;
    } else {
      this.range[1] = event;
    }
    // const end: Date = telemetry.Timestamp;
    if (
      this.range[0].toISOString() !== this.timeframe.start.toISOString() ||
      this.range[1].toISOString() !== this.timeframe.end.toISOString()
    ) {
      this.Timeframe = {
        start: this.range[0],
        end: this.range[1],
        custom: true,
      };
    }
    this.changeRef.detectChanges();

    // make sure that the datepicker really closes
    setTimeout(() => {
      data.openRangePicker = 0;
      this.changeRef.detectChanges();
    });
  }

  openRangePicker(id, data) {
    data.openRangePicker = id.includes('end') ? 2 : 1;
    const el: any = document.getElementById(id);
    if (!!el) el.click();
  }

  onRangePickerSelect(event, data) {
    this.range = event;
    this.changeRef.detectChanges();
  }

  cancelRangePick(data) {
    data.openRangePicker = 0;
    if (this.range[0] > this.range[1]) {
      const temp = this.range[0];
      this.range[0] = this.range[1];
      this.range[1] = temp;
    }
    this.datePickers.forEach((item) => item.close());
  }

  rangePickerDisabledDate(data, pickerIndex): (current: Date) => boolean {
    if (!data) {
      return (current: Date) => false;
    }
    let otherDate = null; // otherDate removes the ability to create empty timespans (in other words, pick the same day twice)
    if (pickerIndex === 1) otherDate = this.range[1];
    if (pickerIndex === 2) otherDate = this.range[0];
    if (!!otherDate) otherDate = new Date(otherDate.toDateString()); // remove time from datetime object
    let first: Date = new Date(Date.parse(data.metadata.firstMeasurement));
    first = new Date(first.toDateString()); // remove time from datetime object
    let last: Date = new Date(Date.parse(data.metadata.lastMeasurement));
    last = new Date(last.toDateString()); // remove time from datetime object
    const fn = (current: Date) => {
      current = new Date(current.toDateString()); // remove time from datetime object
      return current < first || current > last || +current === +otherDate;
    };
    return fn;
  }

  goBack() {
    if (this.IsSensorHealth) {
      this.router.navigate(['sensor-health', this.asset.objectID]);
    } else {
      this.router.navigate(['asset', this.assetID, -1], { replaceUrl: true });
    }
  }

  noData(data: GraphData) {
    return !data || !data.telemetry.length;
  }
  noDataTelemetry() {
    return !this.VisibleTelemetryGraphs.find(
      (data: GraphData) => !this.noData(data)
    );
  }

  isBreadcrumbClickable(breadcrumb: Location) {
    return true; // Made all breadcrumbs clickable. This function is now deprecated.
    /*return (
      !!breadcrumb.curLayer &&
      breadcrumb.curLayer !== 'a' &&
      breadcrumb.curLayer !== 'b'
    );*/
  }

  navToBreadcrumb(breadcrumb: Location) {
    if (!this.isBreadcrumbClickable(breadcrumb)) return;
    if (breadcrumb.isAsset)
      this.router.navigate(['asset', breadcrumb.objectID, -1]);
    else if (breadcrumb.layerID === 1 || breadcrumb.layerID === 1)
      // is region or country
      this.router.navigate(['home', 'list', breadcrumb.objectID]);
    else this.router.navigate(['plant', breadcrumb.objectID]);
  }
}

function isValidDate(d: any) {
  return d instanceof Date && !isNaN(+d);
}
