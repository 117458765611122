import { ElementRef } from '@angular/core';
import * as d3 from 'd3';

import { UserSettings } from '../../model/settings';
import { I18nService } from '../../services/i18n.service';
import { ValueRange, ValueRangeType } from './value-range';
import { Visualization } from './visualization.interface';

const LOCALE_EN = {
  dateTime: '%x, %X',
  date: '%-m/%-d/%Y',
  time: '%-I:%M:%S %p',
  periods: ['AM', 'PM'],
  days: [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
  shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  shortMonths: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
};

const LOCALE_DE = {
  dateTime: '%A, der %e. %B %Y, %X',
  date: '%d.%m.%Y',
  time: '%H:%M:%S',
  periods: ['AM', 'PM'],
  days: [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
  ],
  shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
  months: [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
  ],
  shortMonths: [
    'Jan',
    'Feb',
    'Mrz',
    'Apr',
    'Mai',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dez',
  ],
};

export class LineChart implements Visualization {
  private x_domain = null; // this defines the section of the historical data we want to see
  private y_domain = null; // this defines the section of the values we are interested in
  private valueRanges: ValueRange[];
  private y_unit: string = '';
  private booleanDomain: boolean = false;
  private measurementId: number;
  public booleanDomainTrueMessage: String = '';
  public booleanDomainFalseMessage: String = '';
  private customRange: boolean = false;
  // TODO hardcoded for dev purposes

  private data: any[];
  public updateChart;
  private resetBrush;
  private brushCallback;
  private brushResetCallback;

  constructor(
    private chartContainer: ElementRef,
    private id: string,
    private i18n: I18nService
  ) {}

  public render(data: any[], isMobile, settings: UserSettings) {
    if (!!data) {
      this.data = data.map((t) => {
        return {
          date: new Date(t.timestamp),
          value: t.value,
          text: t.measuredValue,
        };
      });
    }

    this.renderGraph(isMobile, settings);
  }

  public SetValueUnit(unit: string) {
    this.y_unit = unit;
  }

  public SetXDomain(start: Date, end: Date, custom: boolean = false) {
    // set right graph cutoff to midnight of the current day (or next day 00:00):

    if (!custom && (end.getHours() > 0 || end.getMinutes() > 0)) {
      end = new Date(new Date(end).setDate(end.getDate() + 1));
    }
    if (!custom) start = new Date(start.toDateString());
    if (!custom) end = new Date(end.toDateString());
    this.x_domain = d3.extent([start, end], (d) => d);
    this.customRange = custom;
    if (!!this.updateChart) this.updateChart();
  }
  public ResetXDomain() {
    this.x_domain = null;
  }
  public SetYDomain(
    domain: [number, number],
    booleanDomain: boolean = false,
    measurementID: number
  ) {
    if (booleanDomain) {
      this.y_domain = [-0.4, 1.4];
      this.booleanDomain = true;
    } else if (measurementID === 1028) {
      this.y_domain = [domain[1], domain[0]];
      this.measurementId = measurementID;
    } else {
      // if there are no ranges defined, this function might be called with min = infinity and max = -infinity, which causes problems
      // solution: leave this.y_domain as is
      if (domain[1] > domain[0]) {
        this.y_domain = domain;
      }
    }
    if (!!this.updateChart) this.updateChart();
  }
  public ResetYDomain() {
    this.y_domain = null;
  }
  public SetValueRanges(ranges: ValueRange[]) {
    this.valueRanges = ranges;
  }

  private renderGraph(isMobile: boolean, settings: UserSettings) {
    if (!this.chartContainer) return;
    d3.select('svg#' + this.id).remove();

    const max_width = this.chartContainer.nativeElement.clientWidth;
    const max_height = this.chartContainer.nativeElement.clientHeight;
    const isSafari = navigator.userAgent.indexOf('Safari') !== -1;
    // set the dimensions and margins of the graph
    const margin = {
        top: 25,
        right: 65,
        bottom: 71,
        left: 0,
      },
      width = max_width - margin.left - margin.right,
      height = max_height - margin.top - margin.bottom;

    // on mobile renderGraph() sometimes seems to get called with too small clientHeights
    // since it gets called again later with the correct height, we can skip further execution for now
    if (height < 0) return;

    let svg,
      xAxis,
      yAxis,
      yearAxis, // second y axis only showing the current year
      clipMask,
      focusLine,
      focusDate, // date under the vertical line on the right most side of the chart
      focusDateBG,
      focusValue,
      focusValueLine,
      focusValueBG,
      circlePointInner, // dot marking the current value on the right most line of the chart
      circlePointOuter;

    let unique_identifier,
      element, // html element containing the chart
      x, // domain function for determining pixels from x domain
      y, // domain function for determining pixels from y domain
      x_extent, // x domain extent as array [min,max]
      y_extent, // y domain extent as array [min,max]
      bisect, // gets the next x value in the dataset from a given x value (date)
      currentData, // is set to the right most data point
      sx, // horizontal pixel position of the right most data point
      sy, // vertical pixel position of the right most data point
      warning, // whether right most data point is in a warning range
      critical, // whether right most data point is in a critical range
      noData; // true if we literally don't have any data;

    const drawChart = () => {
      init();
      drawAxes();
      drawClipmask();
      drawHorizontalLines();
      if (!noData) drawRanges();
      if (!noData) drawCurve();
      if (!noData) drawFocus();
      initBrushing();
      if (noData) drawNoDataIndicator();
    };

    const init = () => {
      unique_identifier = generateUniqueIdentifier();
      element = this.chartContainer.nativeElement;
      noData = this.data.length < 2;
      // avoid errors
      if (noData) {
        this.data = [
          {
            date: new Date(new Date().setDate(new Date().getDate() - 7)),
            value: 0,
            text: '0',
          },
          { date: new Date(), value: 100, text: '100' },
        ];
      }
      x_extent = d3.extent(this.data, (d) => {
        return d.date;
      });
      if (!this.x_domain) this.x_domain = [x_extent[0], x_extent[1]];
      const partial_data = this.data.filter(
        (item) => item.date >= this.x_domain[0] && item.date <= this.x_domain[1]
      );
      if (this.booleanDomain) {
        y_extent = [this.y_domain[0], this.y_domain[1]];
      } else if (this.measurementId === 1028) {
        y_extent = [this.y_domain[0], this.y_domain[1]];
      } else {
        const temp = [
          d3.min(partial_data, (d) => {
            return +d.value;
          }),
          d3.max(partial_data, (d) => {
            return +d.value;
          }),
        ];
        // temp[0] = Math.min(temp[0], 0);
        y_extent = !!this.y_domain
          ? [
              Math.min(temp[0], this.y_domain[0]),
              Math.max(temp[1], this.y_domain[1]),
            ]
          : temp;

        if (y_extent[0] === y_extent[1]) {
          const e = 0.1 * y_extent[0];
          y_extent = [y_extent[0] - e, y_extent[0] + e];
        }
      }
      // adding a 10 % buffer to the scale
      const buffer = this.booleanDomain ? 0 : (y_extent[1] - y_extent[0]) * 0.1;

      x = d3
        .scaleTime()
        .domain(x_extent)
        .range([20, width - 20]);
      y = d3
        .scaleLinear()
        .domain([y_extent[0] - buffer, y_extent[1] + buffer])
        .range([height, 0]);
      // y = d3.scaleLinear().domain(y_extent).range([height, 0]);

      bisect = (data, _x) => {
        const bisect_right = d3.bisector((d) => {
          return d.date;
        }).right;
        const right = bisect_right(data, _x);
        if (right === 0) return right;
        const left = right - 1;
        if (!data[right]) return left;
        const diff1 = _x - data[left].date;
        const diff2 = data[right].date - _x;
        return diff1 < diff2 ? left : right;
      };

      currentData = this.data[bisect(this.data, x_extent[1])];
      sx = x(currentData.date);
      sy = y(currentData.value);

      warning = !!this.valueRanges.find(
        (range) =>
          range.type === ValueRangeType.yellow &&
          range.min <= currentData.value &&
          range.max >= currentData.value
      );
      critical = !!this.valueRanges.find(
        (range) =>
          range.type === ValueRangeType.red &&
          range.min <= currentData.value &&
          range.max >= currentData.value
      );
      initSVG();
    };

    const initSVG = () => {
      // append the svg object to the body of the page
      svg = d3
        .select(element)
        .append('svg')
        .attr('id', this.id)
        .attr('width', width + margin.left + margin.right)
        .attr('height', height + margin.top + margin.bottom)
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

      // svg.on('click', mousemove);

      // filters go in defs element
      const defs = svg.append('defs');

      const filter = defs
        .append('filter')
        .attr('id', 'pill-gradient')
        .attr('height', '1000%')
        .attr('width', '1000%')
        .attr('x', '-500%')
        .attr('y', '-500%');

      filter
        .append('feColorMatrix')
        .attr('type', 'matrix')
        .attr('values', '0 0 0 1 0  0 0 0 1 0  0 0 0 1 0  0 0 0 1 0');

      filter
        .append('feGaussianBlur')
        .attr('stdDeviation', 2)
        .attr('result', 'coloredBlur');

      const feMerge = filter.append('feMerge');

      feMerge.append('feMergeNode').attr('in', 'coloredBlur');
      //    .attr("in", "SourceAlpha");
    };

    const drawClipmask = () => {
      // Add a clipPath: everything out of this area won't be drawn.
      svg
        .append('defs')
        .append('svg:clipPath')
        .attr('id', 'line-clip-' + this.id)
        .append('svg:rect')
        .attr('width', width)
        .attr('height', height + 6)
        .attr('x', 0)
        .attr('y', -5);

      // Create the line variable: where both the line and the brush take place
      clipMask = svg
        .append('g')
        .attr('clip-path', 'url(#line-clip-' + this.id + ')');
    };

    const drawAxes = () => {
      svg
        .append('rect')
        .style('fill', 'rgb(255, 255, 255)')
        .style('cursor', 'pointer')
        .attr('x', -1)
        .attr('y', 0)
        .attr('width', width + 1)
        .attr('height', height);
      if (!noData) {
        xAxis = svg
          .append('g')
          .attr('id', 'x-axis')
          .call(
            d3
              .axisBottom(x)
              .tickSize(height)
              .tickPadding(10)
              .tickFormat((d) => {
                return '';
              })
          )
          .call((g) =>
            g
              .selectAll('.tick line')
              .attr('stroke', '#e6e7e7')
              .style('cursor', 'pointer')
          )
          .call((g) => g.select('.domain').remove())
          .call((g) =>
            g
              .selectAll('.tick text')
              .attr('color', '#afb4b9')
              .attr('font-size', 12)
              .attr('font-family', 'Roboto')
              .attr('font-weight', '700')
              .attr('line-height', 18)
              .attr('letter-spacing', 0)
              .attr('transform', 'translate(0,-3)')
          );
        yearAxis = svg
          .append('g')
          .attr('id', 'year-axis')
          .call(
            d3
              .axisBottom(x)
              .tickSize(height + 28)
              .ticks(d3.timeYear)
              .tickPadding(15)
              .tickFormat((d) => {
                return d3.timeFormat('%Y')(d);
              })
          )
          .call((g) => g.selectAll('.tick line').attr('stroke', '#e6e7e7'))
          .call((g) => g.select('.domain').remove())
          .call((g) => g.selectAll('.tick text').attr('color', '#afb4b9'));
      }

      const false_icon =
        '<i nz-icon nzType="icons:status-check" class="mini-svg-icon fillgreen"></i>';
      const true_icon =
        '<i nz-icon nzType="icons:status-exclamation-triangle" class="mini-svg-icon fillwhite"></i>';

      yAxis = svg
        .append('g')
        .attr('id', 'y-axis')
        .attr('transform', 'translate(' + width + ', 0)')
        .call(
          this.booleanDomain
            ? d3
                .axisRight(y)
                .tickValues([0, 1])
                .tickFormat((d) => d)
            : this.measurementId === 1028
            ? d3
                .axisRight(y)
                .tickValues([-90, -100, -110, -120])
                .tickFormat((d) => d)
            : d3
                .axisRight(y)
                .ticks(6)
                .tickFormat((d) => {
                  return d + ' ' + this.y_unit;
                })
        )
        .call((g) => g.select('.domain').remove())
        .call((g) => g.selectAll('.tick line').remove())
        .call((g) =>
          g
            .selectAll('.tick text')
            .attr('x', 4)
            .attr('color', '#afb4b9')
            .attr('font-size', 12)
            .attr('font-family', 'Roboto')
            .attr('font-weight', '700')
            .attr('line-height', 18)
            .attr('letter-spacing', 0)
            .attr('text-anchor', 'end')
            .attr('dx', 35)
        );

      svg.select('.axis').selectAll('text').remove();
      if (this.booleanDomain) {
        yAxis.selectAll('.tick text').remove();
        yAxis
          .selectAll('.tick:first-child')
          .append('svg:image')
          .attr('xlink:href', function (d) {
            return 'assets/icons/tilt_untilted.svg';
          })
          .attr('width', 48)
          .attr('height', 48)
          .attr('x', 4)
          .attr('y', -36);

        yAxis
          .selectAll('.tick:last-child')
          .append('svg:image')
          .attr('xlink:href', function (d) {
            return 'assets/icons/tilt_tilted.svg';
          })
          .attr('width', 48)
          .attr('height', 48)
          .attr('x', 4)
          .attr('y', -32);
        svg
          .append('g')
          .append('text')
          .html(this.booleanDomainTrueMessage)
          .attr('y', y(1) - 10)
          .attr('x', width / 2)
          .attr('font-size', 12)
          .attr('font-family', 'Roboto')
          .attr('font-weight', '700')
          .attr('line-height', 18)
          .attr('fill', '#E1000F')
          .attr('letter-spacing', 0)
          .attr('text-anchor', 'middle');
        //  .attr('dx', 35);
        svg
          .append('g')
          .append('text')
          .html(this.booleanDomainFalseMessage)
          .attr('y', y(0) + 20)
          .attr('x', width / 2)
          .attr('font-size', 12)
          .attr('font-family', 'Roboto')
          .attr('font-weight', '700')
          .attr('line-height', 18)
          .attr('letter-spacing', 0)
          .attr('text-anchor', 'middle');
        //  .attr('dx', 35);
      }
      if (this.measurementId === 1028) {
        yAxis.selectAll('.tick text').remove();
        yAxis
          .selectAll('.tick:first-child')
          .append('g')
          .append('text')
          .html('Good -90dBm')
          .attr('y', y(0) + 3)
          .attr('x', width / 2)
          .attr('font-size', 12)
          .attr('font-family', 'Roboto')
          .attr('font-weight', '700')
          .attr('line-height', 18)
          .attr('fill', '#00aa75')
          .attr('letter-spacing', 0)
          .attr('text-anchor', 'start')
          .attr('width', 48)
          .attr('height', 48)
          .attr('x', 4)
          .attr('y', -32)
          .call(wrap, 11);

        yAxis
          .selectAll('.tick:nth-child(2)')
          .append('g')
          .append('text')
          .html('Fair -100dBm')
          .attr('font-size', 12)
          .attr('font-family', 'Roboto')
          .attr('font-weight', '700')
          .attr('line-height', 18)
          .attr('fill', '#AFB4B9')
          .attr('letter-spacing', 0)
          .attr('text-anchor', 'start')
          .attr('width', 48)
          .attr('height', 48)
          .attr('x', 4)
          .attr('y', -32)
          .call(wrap, 11);

        yAxis
          .selectAll('.tick:nth-child(3)')
          .append('g')
          .append('text')
          .html('Poor -110dBm')
          .attr('font-size', 12)
          .attr('font-family', 'Roboto')
          .attr('font-weight', '700')
          .attr('line-height', 18)
          .attr('fill', '#ECB102')
          .attr('letter-spacing', 0)
          .attr('text-anchor', 'start')
          .attr('width', 48)
          .attr('height', 48)
          .attr('x', 4)
          .attr('y', -32)
          .call(wrap, 11);

        yAxis
          .selectAll('.tick:last-child')
          .append('g')
          .append('text')
          .html('Dead Zone -120dBm')
          .attr('font-size', 12)
          .attr('font-family', 'Roboto')
          .attr('font-weight', '700')
          .attr('line-height', 18)
          .attr('fill', '#E1000F')
          .attr('letter-spacing', 0)
          .attr('text-anchor', 'start')
          .attr('width', 48)
          .attr('height', 48)
          .attr('x', 4)
          .attr('y', -15)
          .call(wrap, 58);
      }
    };
    let unbrushed_domain = null;
    const initBrushing = () => {
      // Add brushing
      const brush = d3
        .brushX() // Add the brush feature using the d3.brush function
        .extent([
          [0, -6],
          [sx, height + 12],
          // making the brushing taller than the clipmask hides the top and bottom strokes so that we only have
          // solid blue lines at the left and right
        ])
        // initialise the brush area: start at 0,0 and finishes at sx,height: it means I select the whole graph area
        .on('end', () => {
          if (!d3.event.sourceEvent) return;
          const extent = d3.event.selection;
          const epsilon =
            0.008 * Math.abs(+this.x_domain[1] - +this.x_domain[0]);
          if (
            !extent ||
            !extent[0] ||
            Math.abs(+x.invert(extent[1]) - +x.invert(extent[0])) < epsilon
          ) {
            mousemove(!!extent ? extent[0] : d3.event.sourceEvent.offsetX);
          } else {
            if (minutesBetween(x.invert(extent[0]), x.invert(extent[1])) > 60) {
              if (!unbrushed_domain) {
                unbrushed_domain = this.x_domain;
              }
              this.SetXDomain(x.invert(extent[0]), x.invert(extent[1]), true);
              if (!!this.brushCallback) this.brushCallback(this.x_domain);
            }
          }
          // This remove the grey brush area as soon as the selection has been done
          setTimeout(() => svg.select('.brush').call(brush.move, null));
        });

      this.resetBrush = () => {
        if (!!unbrushed_domain)
          this.SetXDomain(unbrushed_domain[0], unbrushed_domain[1]);

        unbrushed_domain = null;
        if (!!this.brushResetCallback) this.brushResetCallback(this.x_domain);

        updateFocusline(
          that.data[bisect(that.data, that.x_domain[1])].date,
          true
        );
        updateYAxis(that.data[bisect(that.data, that.x_domain[1])].value);

        unbrushed_domain = null;
        if (!!this.brushResetCallback) this.brushResetCallback();
      };
      svg.on('dblclick', this.resetBrush);
      // Add the brushing
      clipMask.append('g').attr('class', 'brush').call(brush);
      clipMask.selectAll('.selection').attr('fill-opacity', 1);
    };
    const drawHorizontalLines = () => {
      clipMask // appends to line object for clipping purposes
        .append('line')
        .attr('x1', 0)
        .attr('x2', width)
        .attr('y1', y(0))
        .attr('y2', y(0))
        .attr('stroke', this.booleanDomain ? '#000000' : '#979b9b');
      if (this.booleanDomain) {
        clipMask
          .append('line')
          .attr('x1', 0)
          .attr('x2', width)
          .attr('y1', y(1))
          .attr('y2', y(1))
          .attr('stroke', '#E1000F');
      }

      clipMask
        .append('line')
        .attr('x1', 0)
        .attr('x2', width)
        .attr('y1', height)
        .attr('y2', height)
        .attr('stroke', '#e6e7e7');
      svg
        .append('line')
        .attr('x1', 0)
        .attr('x2', max_width)
        .attr('y1', height + 31)
        .attr('y2', height + 31)
        .attr('stroke', '#e6e7e7');
    };
    const drawNoDataIndicator = () => {
      svg
        .append('text')
        .html('No data')
        .attr('y', 16)
        .attr('x', 36)
        .style('opacity', 0.6);
    };
    const drawRanges = () => {
      for (const range of this.valueRanges) {
        if (range.type === ValueRangeType.red) {
          // currently, we only display critical range
          if (critical) {
            clipMask
              .append('rect')
              .style('fill', '#E1000F')
              .style('cursor', 'pointer')
              .style('opacity', 0.05)
              .attr('x', 0)
              .attr('y', y(range.max))
              .attr('width', width)
              .attr('height', y(range.min) - y(range.max));
          }
          if (range.min > y_extent[0])
            clipMask
              .append('line')
              .attr('x1', 0)
              .attr('x2', width)
              .attr('y1', y(range.min))
              .attr('y2', y(range.min))
              .attr('stroke', '#E1000F')
              .style('cursor', 'pointer');
          if (range.max < y_extent[1])
            clipMask
              .append('line')
              .attr('x1', 0)
              .attr('x2', width)
              .attr('y1', y(range.max))
              .attr('y2', y(range.max))
              .attr('stroke', '#E1000F')
              .style('cursor', 'pointer');
        }
      }

      const gradient_test = d3.range(100).map((d) => ({
        offset: d + '%',
        color: d3.interpolateRainbow(d / 100),
      }));

      // Set the gradient
      clipMask
        .append('linearGradient')
        .attr('id', 'line-gradient' + unique_identifier)
        .attr('gradientUnits', 'userSpaceOnUse')
        .attr('x1', 0)
        .attr('y1', y(y_extent[0]))
        .attr('x2', 0)
        .attr('y2', y(y_extent[1]))
        .selectAll('stop')
        // .data(gradient_test)
        .data(
          generateCriticalGradient(
            this.valueRanges,
            y_extent,
            critical,
            this.booleanDomain
          )
        )
        .enter()
        .append('stop')
        .attr('offset', (d) => {
          return d.offset;
        })
        .attr('stop-color', (d) => {
          return d.color;
        });
    };

    const drawCurve = () => {
      clipMask
        .append('path')
        .attr('id', 'datapath')
        .datum(this.data)
        .attr('class', 'curve') // I add the class .curve to be able to modify this line later on.
        .attr('fill', 'none')
        .attr('stroke', 'url(#line-gradient' + unique_identifier + ')')
        .attr('stroke-width', 3)
        .style('cursor', 'pointer')
        .attr(
          'd',
          isSafari
            ? d3
                .line()
                .x((d) => {
                  return x(d.date);
                })
                .y((d) => {
                  return y(d.value);
                })
            : d3
                .line()
                .x((d) => {
                  return x(d.date);
                })
                .y((d) => {
                  return y(d.value);
                })
                .curve(d3.curveMonotoneX)
        );
    };

    const drawFocus = () => {
      if (this.data.length < 2) return;

      // adding a 10 % buffer to the scale
      const buffer = this.booleanDomain ? 0 : (y_extent[1] - y_extent[0]) * 0.1;

      focusLine = svg
        .append('g')
        .append('line')
        .attr('x1', sx)
        .attr(
          'y1',
          y(
            (this.y_domain
              ? this.y_domain[1]
              : d3.max(this.data, (d) => {
                  return +d.value;
                })) + buffer
          )
        )
        .attr('x2', sx)
        .attr(
          'y2',
          y(
            (this.y_domain
              ? this.y_domain[0]
              : d3.min(this.data, (d) => {
                  return +d.value;
                })) - buffer
          )
        )
        .attr('stroke', 'black')
        .style('cursor', 'pointer');
      focusDateBG = svg
        .append('rect')
        .style('fill', 'white')
        .attr('x', sx)
        .attr('y', height + 7)
        .attr('width', 35)
        .attr('height', 12)
        .style('filter', 'url(#pill-gradient)')
        .attr('rx', '3')
        .attr('ry', '3')
        .style('transform', 'translate(-18px, 0px)');
      focusDate = svg
        .append('text')
        .html(
          d3.timeFormat(
            !!settings.DateFormat &&
              settings.DateFormat.formatString === 'MM/dd/yyyy'
              ? '%m/%d'
              : '%e-%m'
          )(this.data[this.data.length - 1].date)
        )
        .attr('font-size', 12)
        .attr('font-family', 'Roboto')
        .attr('font-weight', '700')
        .attr('line-height', 18)
        .attr('letter-spacing', 0)
        .attr('dy', -4)
        .attr('class', 'brushText2Time')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'middle')
        .attr('y', height + (isSafari ? 16 : 21))
        .attr('x', sx);

      focusValueBG = svg
        .append('rect')
        .style('fill', 'white')
        .attr('x', width)
        .attr('y', sy)
        .attr('width', 46)
        .attr('height', 12)
        .attr('opacity', 0)
        .style('filter', 'url(#pill-gradient)')
        .attr('rx', '3')
        .attr('ry', '3');
      if (this.measurementId === 1028) {
        focusValue = svg
          .append('rect')
          .html(that.data[that.data.length - 1].value + this.y_unit)
          .attr('x', width)
          .attr('y', sy)
          .attr('fill', critical ? '#E1000F' : warning ? '#FFCC33' : '#00AA75')
          .attr('font-size', 12)
          .attr('font-family', 'Roboto')
          .attr('font-weight', '700')
          .attr('line-height', 18)
          .attr('letter-spacing', 0)
          .attr('text-anchor', 'end')
          .attr('dx', 40)
          .attr('dy', 4);

        focusValueLine = svg
          .append('rect')
          .attr('x1', sx)
          .attr('x2', width - 5)
          .attr('y1', sy)
          .attr('y2', sy);
      } else {
        focusValue = svg
          .append('text')
          .html(that.data[that.data.length - 1].value + this.y_unit)
          .attr('x', width)
          .attr('y', sy)
          .attr('fill', critical ? '#E1000F' : warning ? '#FFCC33' : '#00AA75')
          .attr('font-size', 12)
          .attr('font-family', 'Roboto')
          .attr('font-weight', '700')
          .attr('line-height', 18)
          .attr('letter-spacing', 0)
          .attr('text-anchor', 'end')
          .attr('dx', 40)
          .attr('dy', 4);

        focusValueLine = svg
          .append('line')
          .attr('x1', sx)
          .attr('x2', width - 5)
          .attr('y1', sy)
          .attr('y2', sy)
          .attr(
            'stroke',
            critical ? '#E1000F' : warning ? '#FFCC33' : '#00AA75'
          );
      }
      circlePointInner = svg
        .append('circle')
        .attr('class', 'inner')
        .attr('fill', critical ? '#E1000F' : warning ? '#FFCC33' : '#00AA75')
        .attr('cx', sx)
        .attr('cy', sy)
        .attr('r', 8);
      circlePointOuter = svg
        .append('circle')
        .attr('class', 'outer')
        .attr('cx', sx)
        .attr('cy', sy)
        .attr('fill', 'transparent')
        .attr('stroke', critical ? '#E1000F' : warning ? '#FFCC33' : '#00AA75')
        .attr('r', 12);
    };

    this.updateChart = () => {
      // update the chart for given boundaries / x domain
      if (!noData) updateXAxis();
      if (!noData) updateYAxis();
      if (!noData) updateCurve();
      if (!noData) updateFocusPoint();
      mouseout();
    };

    const updateCurve = () => {
      clipMask
        .select('.curve')
        .transition()
        .duration(1000)
        .attr(
          'd',
          isSafari
            ? d3
                .line()
                .x((d) => {
                  return x(d.date);
                })
                .y((d) => {
                  return y(d.value);
                })
            : d3
                .line()
                .x((d) => {
                  return x(d.date);
                })
                .y((d) => {
                  return y(d.value);
                })
                .curve(d3.curveMonotoneX)
        );
    };
    const updateGanttBars = () => {
      clipMask
        .selectAll('.gantt-bar')
        .transition()
        .duration(1000)
        .attr('x', (d) => x(d.start) + (x(d.end) - x(d.start) - 2 <= 0 ? 0 : 1))
        .attr('width', (d) => Math.max(x(d.end) - x(d.start) - 2, 1));
      updateCurve();
    };

    const that = this;
    let selectedData;

    function mousemove(_x?: any) {
      if (noData) return;
      if (!_x && !this) return;
      updateFocusline(x.invert(_x ? _x : d3.mouse(this)[0]));
      updateYAxis(
        that.data[bisect(that.data, x.invert(_x ? _x : d3.mouse(this)[0]))]
          .value
      );
    }

    function mouseout() {
      if (noData) return;
      updateFocusline(
        that.data[bisect(that.data, that.x_domain[1])].date,
        true
      );
      updateYAxis(that.data[bisect(that.data, that.x_domain[1])].value);
      focusValue.attr('opacity', that.booleanDomain ? 0 : 1);
      focusValueBG.attr('opacity', 0);
      focusValueLine.attr('opacity', 0);
    }

    function updateFocusline(x0, reset = false) {
      if (noData) return;
      //  if (that.booleanDomain) return;
      const left_margin = 18;
      const right_margin = 25;
      if (!that.data || that.data.length === 0) return;
      const i = bisect(that.data, x0.getTime());
      selectedData = that.data[i];
      let _sx = x(selectedData.date);
      if (_sx < 0) _sx = 0;
      if (_sx > width) _sx = width;
      let tx = _sx;
      if (tx < left_margin) tx = left_margin;
      if (tx > width - right_margin) tx = width - right_margin;
      if (selectedData) {
        focusLine
          .transition()
          .duration(reset ? 1000 : 500)
          .attr('x1', _sx)
          .attr('x2', _sx);
        warning = !!that.valueRanges.find(
          (range) =>
            range.type === ValueRangeType.yellow &&
            range.min <= selectedData.value &&
            range.max >= selectedData.value
        );
        critical = that.booleanDomain
          ? selectedData.value > 0
          : !!that.valueRanges.find(
              (range) =>
                range.type === ValueRangeType.red &&
                range.min <= selectedData.value &&
                range.max >= selectedData.value
            );
        sy = y(selectedData.value);
        circlePointInner
          .transition()
          .duration(reset ? 1000 : 500)
          .attr('cy', sy)
          .attr('cx', _sx)
          .attr('fill', critical ? '#E1000F' : warning ? '#FFCC33' : '#00AA75');
        circlePointOuter
          .transition()
          .duration(reset ? 1000 : 500)
          .attr('cy', sy)
          .attr('cx', _sx)
          .attr(
            'stroke',
            critical ? '#E1000F' : warning ? '#FFCC33' : '#00AA75'
          );
        focusDate
          .html(
            d3.timeFormat(
              !!settings.DateFormat &&
                settings.DateFormat.formatString === 'MM/dd/yyyy'
                ? '%m/%d'
                : '%e-%m'
            )(selectedData.date)
          )
          .transition()
          .duration(reset ? 1000 : 500)
          .attr('x', _sx);

        focusDateBG
          .transition()
          .duration(reset ? 1000 : 500)
          .attr('x', _sx);
        //if (!reset) {
        focusValue
          .html(selectedData.value + that.y_unit)
          .transition()
          .duration(reset ? 1000 : 500)
          .attr('y', sy)
          .attr('fill', critical ? '#E1000F' : warning ? '#FFCC33' : '#00AA75')
          .attr('opacity', that.booleanDomain ? 0 : 1);
        focusValueBG
          .transition()
          .duration(reset ? 1000 : 500)
          .attr('y', sy - 14)
          .attr('opacity', that.booleanDomain ? 0 : 0);
        if (!reset) {
          focusValueLine
            .transition()
            .duration(reset ? 1000 : 500)
            .attr('x1', _sx)
            .attr('y1', sy)
            .attr('y2', sy)
            .attr(
              'stroke',
              critical ? '#E1000F' : warning ? '#FFCC33' : '#00AA75'
            )
            .attr('opacity', that.booleanDomain ? 0 : 1);
        } else {
          /*focusValue
            .html(selectedData.value + that.y_unit)
            .attr('y', sy)
            .attr(
              'fill',
              critical ? '#E1000F' : warning ? '#FFCC33' : '#00AA75'
            );*/
          focusValueLine
            .attr('x1', _sx)
            .attr('y1', sy)
            .attr('y2', sy)
            .attr(
              'stroke',
              critical ? '#E1000F' : warning ? '#FFCC33' : '#00AA75'
            );

          // we use transition here mainly because the transition from a previous call might overwrite opacity if it
          // occured less then 0.5 seconds ago
          // focusValue.transition().duration(500).attr('opacity', 1);
          focusValueLine.transition().duration(500).attr('opacity', 0);
          // focusValueBG.transition().duration(500).attr('opacity', 0);
        }
      }
    }

    function formatDate(date) {
      const o_date_en = new Intl.DateTimeFormat('en');
      return o_date_en.format(date);
    }

    const updateXAxis = () => {
      if (this.x_domain) {
        x.domain(this.x_domain);
      }

      if (!this.customRange) unbrushed_domain = null;

      const tickCall = generateTicks(this.x_domain[0], this.x_domain[1]);

      xAxis
        .transition()
        .duration(1000)
        .call(tickCall)
        .call((g) => g.selectAll('.tick line').attr('stroke', '#e6e7e7'))
        .call((g) => g.select('.domain').remove())
        .call((g) =>
          g
            .selectAll('.tick text')
            .attr('color', '#afb4b9')
            .attr('font-size', isMobile ? '12px' : '14px')
            .attr('font-family', 'Roboto')
            .attr('font-weight', '700')
            .attr('line-height', '18px')
            .attr('letter-spacing', 0)
            .attr('transform', 'translate(0,-3)')
        );

      const yearTransitionInRange =
        d3.timeYear.range(this.x_domain[0], this.x_domain[1]).length > 0;
      yearAxis
        .transition()
        .duration(1000)
        .call(
          d3
            .axisBottom(x)
            .tickSize(height + (isSafari ? 30 : 28))
            .tickValues(
              yearTransitionInRange
                ? d3.timeYear.range(this.x_domain[0], this.x_domain[1])
                : [
                    new Date(
                      (this.x_domain[0].getTime() +
                        this.x_domain[1].getTime()) /
                        2
                    ),
                  ]
            )
            .tickPadding(15)
            .tickFormat((d) => {
              return !noData ? d3.timeFormat('%Y')(d) : '';
            })
        )
        .call((g) => g.selectAll('.tick line').attr('stroke', 'transparent'))
        .call((g) => g.select('.domain').remove())
        .call(
          (g) =>
            g
              .selectAll('.tick text')
              .attr('color', '#afb4b9')
              .attr('font-size', 12)
              .attr('font-family', 'Roboto')
              .attr('font-weight', '700')
              .attr('line-height', 18)
              .attr('letter-spacing', 0)
              .attr(
                'dx',
                yearTransitionInRange ? 0 : (margin.right - margin.left) / 2
              ) // this centers the year in the wrapper when the year tick is not placed at the year transition
        )
        .call((g) =>
          g.selectAll('.tick:last-child text').attr('color', 'black')
        );
    };
    const updateFocusPoint = () => {
      if (this.data.length < 2) return;
      currentData = this.data[bisect(this.data, this.x_domain[1])];
      critical = this.booleanDomain
        ? currentData.value > 0
        : !!this.valueRanges.find(
            (range) =>
              range.type === ValueRangeType.red &&
              range.min <= currentData.value &&
              range.max >= currentData.value
          );
      sy = y(currentData.value);
      circlePointInner
        .transition()
        .duration(1000)
        .attr('cy', sy)
        .attr('fill', critical ? '#E1000F' : warning ? '#FFCC33' : '#00AA75');
      circlePointOuter
        .transition()
        .duration(1000)
        .attr('cy', sy)
        .attr('stroke', critical ? '#E1000F' : warning ? '#FFCC33' : '#00AA75');
      focusDate
        .html(
          d3.timeFormat(
            !!settings.DateFormat &&
              settings.DateFormat.formatString === 'MM/dd/yyyy'
              ? '%m/%d'
              : '%e-%m'
          )(this.data[bisect(this.data, this.x_domain[1])].date)
        )
        .attr('font-size', isMobile ? '12px' : '14px')
        .attr('font-family', 'Roboto')
        .attr('font-weight', '700')
        .attr('line-height', '18px')
        .attr('letter-spacing', 0)
        .attr('transform', 'translate(0,2)');
    };

    const updateYAxis = (s = null) => {
      if (this.booleanDomain || this.measurementId === 1028) return;
      if (!s) s = that.data[bisect(that.data, that.x_domain[1])].value;
      const partial_data = this.data.filter(
        (item) => item.date > this.x_domain[0] && item.date < this.x_domain[1]
      );
      if (partial_data.length < 2) return;
      const temp = [
        d3.min(partial_data, (d) => {
          return +d.value;
        }),
        d3.max(partial_data, (d) => {
          return +d.value;
        }),
      ];
      // temp[0] = Math.min(temp[0], 0);
      y_extent = !!this.y_domain
        ? [
            Math.min(temp[0], this.y_domain[0]),
            Math.max(temp[1], this.y_domain[1]),
          ]
        : temp;
      // adding a 10 % buffer to the scale
      const buffer = (y_extent[1] - y_extent[0]) * 0.1;
      // if (this.y_domain) {
      if (y_extent[0] < y_extent[1]) {
        y.domain([y_extent[0] - buffer, y_extent[1] + buffer]);
      }

      let last_d = null;
      let delta = null;
      const epsilon = 0.3;

      yAxis
        .transition()
        .duration(1000)
        .call(
          d3
            .axisRight(y)
            .ticks(6)
            .tickFormat((d) => {
              if (!!last_d) delta = d - last_d;
              last_d = d;
              return d + ' ' + this.y_unit;
            })
        )
        .call((g) =>
          g
            .selectAll('.tick text')
            .attr('x', 4)
            .attr('color', '#afb4b9')
            .attr('opacity', (d) => {
              return Math.abs(s - d) < delta * epsilon ? 0 : 1;
            })
            .attr('font-size', 12)
            .attr('font-family', 'Roboto')
            .attr('font-weight', '700')
            .attr('line-height', 18)
            .attr('letter-spacing', 0)
            .attr('text-anchor', 'end')
            .attr('dx', 35)
        );
      yAxis.call((g) => g.selectAll('.tick line').remove());
    };

    function generateTicks(start, end) {
      const small_screen = width < 450;
      const timespan_days = daysBetween(start, end);
      const timespan_hours = hoursBetween(start, end);

      const locale = d3.timeFormatLocale(
        settings.Language.abbreviation === 'de' ? LOCALE_DE : LOCALE_EN
      );

      let ticks: any = d3.timeDay.every(small_screen ? 2 : 1);
      let tickFormat = (d) => {
        return d3.timeFormat(
          !!settings.DateFormat &&
            settings.DateFormat.formatString === 'MM/dd/yyyy'
            ? '%m/%d'
            : '%e-%m'
        )(d);
      };
      let tickValuesRangeEnd = minDate(
        new Date(that.data[that.data.length - 1].date),
        end
      );
      let tickValuesRangeStart = start;
      if (timespan_days > 15) {
        ticks = d3.timeWeek.every(1);
        tickFormat = (d) => {
          return (
            (settings.Language.abbreviation === 'de' ? 'KW' : 'CW') +
            d3.timeFormat('%U')(d)
          );
        };
      }
      if (timespan_days > 45 || (small_screen && timespan_days > 25)) {
        ticks = d3.timeWeek.every(2);
      }
      if (timespan_days > 90 || (small_screen && timespan_days > 60)) {
        ticks = d3.timeMonth.every(1);
        // avoid jumbled month names
        if (!small_screen) {
          const tempTicks = ticks.range(
            tickValuesRangeStart,
            tickValuesRangeEnd
          );
          const widthPerTick = width / tempTicks.length;
          if (widthPerTick < 35) ticks = d3.timeMonth.every(4);
          else if (widthPerTick < 75) ticks = d3.timeMonth.every(2);
        }
        tickFormat = (d) => {
          return locale.format(small_screen ? '%b' : '%B')(d);
        };
      }
      const end_sx = x(that.data[that.data.length - 1].date);
      let tickValues = ticks
        .range(tickValuesRangeStart, tickValuesRangeEnd)
        .filter((d) => {
          return (
            x(d) >=
              (timespan_days > 90 || (small_screen && timespan_days > 60)
                ? 30
                : 20) && x(d) <= end_sx - (small_screen ? 30 : 60)
          );
        }); // filter out ticks which are too far left or too far right which may result in text being cut off

      if (noData) {
        tickFormat = (d) => '';
      }
      let tickCall = d3
        .axisBottom(x)
        .tickSize(height)
        .tickValues(tickValues)
        .tickPadding(12)
        .tickFormat(tickFormat);

      if (that.customRange) {
        tickValuesRangeStart = start;
        tickValuesRangeEnd = end;
        if (timespan_days <= 15 && timespan_days > 7) {
          ticks = d3.timeDay.every(small_screen && timespan_days >= 6 ? 3 : 2);
          tickValues = ticks.range(tickValuesRangeStart, tickValuesRangeEnd);
        } else if (timespan_hours < 3) {
          ticks = d3.timeMinute.every(
            small_screen
              ? timespan_hours < 1
                ? 60
                : 30
              : timespan_hours < 1
              ? 30
              : 15
          );
          tickValues = ticks.range(tickValuesRangeStart, tickValuesRangeEnd);
        } else if (timespan_hours <= 24) {
          ticks = d3.timeHour.every(
            small_screen
              ? timespan_hours < 6
                ? 2
                : timespan_hours < 12
                ? 3
                : 6
              : timespan_hours < 6
              ? 1
              : timespan_hours < 12
              ? 2
              : 3
          );
          tickValues = ticks.range(tickValuesRangeStart, tickValuesRangeEnd);
          if (tickValues.length < 5 && timespan_hours < 6 && !small_screen) {
            tickValues = tickValues
              .map((d) => [
                new Date(new Date(d).setMinutes(d.getMinutes() - 30)),
                d,
                new Date(new Date(d).setMinutes(d.getMinutes() + 30)),
              ])
              .flat(2)
              .filter((d) => d < tickValuesRangeEnd)
              .filter((d) => {
                return (
                  x(d) >=
                    (timespan_days > 90 || (small_screen && timespan_days > 60)
                      ? 30
                      : 20) && x(d) <= end_sx - (small_screen ? 30 : 60)
                );
              }); // filter out ticks which are too far left or too far right which may result in text being cut off
          }
        } else if (timespan_days <= 7) {
          ticks = d3.timeDay.every(small_screen && timespan_days >= 6 ? 2 : 1);
          tickValues = ticks.range(tickValuesRangeStart, tickValuesRangeEnd);
          if (tickValues.length < 5 && !small_screen) {
            tickValues = tickValues
              .map((d) => [
                new Date(new Date(d).setHours(d.getHours() - 12)),
                d,
                new Date(new Date(d).setHours(d.getHours() + 12)),
              ])
              .flat(2)
              .filter((d) => d < tickValuesRangeEnd)
              .filter((d) => {
                return (
                  x(d) >=
                    (timespan_days > 90 || (small_screen && timespan_days > 60)
                      ? 30
                      : 20) && x(d) <= end_sx - (small_screen ? 30 : 60)
                );
              }); // filter out ticks which are too far left or too far right which may result in text being cut off
          }
        }

        const _locale = d3.timeFormatLocale(
          settings.Language.abbreviation === 'de' ? LOCALE_DE : LOCALE_EN
        );
        tickCall = d3
          .axisBottom(x)
          .tickSize(height)
          .tickValues(tickValues)
          .tickPadding(12)
          .tickFormat(
            timespan_days > 90 || (small_screen && timespan_days > 60)
              ? (d) => {
                  return _locale.format(small_screen ? '%b' : '%B')(d);
                }
              : timespan_days > 15
              ? (d) => {
                  return (
                    (settings.Language.abbreviation === 'de' ? 'KW' : 'CW') +
                    d3.timeFormat('%U')(d)
                  );
                }
              : timespan_days <= 1
              ? (d: Date) => {
                  return d3.timeFormat('%H:%M')(d);
                }
              : timespan_days < 7
              ? (d) => {
                  if (d.getHours() > 0 || d.getMinutes() > 0)
                    return _locale.format('%H:%M')(d);
                  else
                    return _locale.format(
                      !!settings.DateFormat &&
                        settings.DateFormat.formatString === 'MM/dd/yyyy'
                        ? '%m/%d'
                        : '%e-%m'
                    )(d);
                }
              : tickFormat
          );
      }

      return tickCall;
    }

    drawChart();
    setTimeout(() => this.updateChart(), 0);
  }

  public OnBrush(callback: (x_domain: any) => void) {
    this.brushCallback = callback;
  }

  public OnBrushReset(callback: (x_domain: any) => void) {
    this.brushResetCallback = callback;
  }

  public ResetBrush() {
    this.resetBrush(); // call to private member
  }
}

function wrap(text, width) {
  text.each(function () {
    var text = d3.select(this),
      words = text.text().split(/\s+/).reverse(),
      word,
      line = [],
      lineNumber = 1,
      lineHeight = 15, // ems
      y = text.attr('y'),
      dy = parseFloat(text.attr('dy')),
      tspan = text
        .text(null)
        .attr('text-anchor', 'start')
        .append('tspan')
        .attr('x', 0)
        .attr('y', y)
        .attr('dy', -2);
    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(' ')).attr('text-anchor', 'start');
      if (tspan.node().getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(' '));
        line = [word];
        tspan = text
          .append('tspan')
          .attr('text-anchor', 'start')
          .attr('x', 0)
          .attr('y', y)
          .attr('dy', lineNumber++ * lineHeight)
          .text(word);
      }
    }
  });
}

function treatAsUTC(date): number {
  const result = new Date(date);
  result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
  return +result;
}
function daysBetween(startDate, endDate): number {
  const millisecondsPerDay = 24 * 60 * 60 * 1000;
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
}

function hoursBetween(startDate, endDate): number {
  const millisecondsPerHour = 60 * 60 * 1000;
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerHour;
}

function minutesBetween(startDate, endDate): number {
  const millisecondsPerMinute = 60 * 1000;
  return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerMinute;
}

function minDate(a: Date, b: Date) {
  return a < b ? a : b;
}

function generateUniqueIdentifier() {
  const _number = Math.random(); // 0.9394456857981651
  _number.toString(36); // '0.xtis06h6'
  return _number.toString(36).substr(2, 9); // 'xtis06h6'
}

function generateCriticalGradient(ranges, y_extent, critical, booleanDomain) {
  if (booleanDomain) {
    return [
      {
        offset: '20%',
        color: 'black',
      },
      {
        offset: '80%',
        color: '#E1000F',
      },
    ];
  }
  if (!critical) {
    return [
      {
        offset: '-100%',
        color: 'black',
      },
      {
        offset: '100%',
        color: 'black',
      },
    ];
  }
  let above_crit_offsets = [];
  let below_crit_offsets = [];

  const above_crit_range = ranges.find(
    (red_range) =>
      red_range.type === ValueRangeType.red &&
      !!ranges.find(
        (green_range) =>
          green_range.type === ValueRangeType.green &&
          green_range.max <= red_range.min
      )
  );

  const below_crit_range = ranges.find(
    (red_range) =>
      red_range.type === ValueRangeType.red &&
      !!ranges.find(
        (green_range) =>
          green_range.type === ValueRangeType.green &&
          green_range.min >= red_range.max
      )
  );
  const full_range = y_extent[1] - y_extent[0];
  const offset = full_range * 0.05;

  if (above_crit_range) {
    const gradient_start = above_crit_range.min - offset - y_extent[0];
    const gradient_end = above_crit_range.min + offset - y_extent[0];

    above_crit_offsets = [
      {
        offset: ((gradient_start / full_range) * 100).toFixed(0) + '%',
        color: 'black',
      },
      {
        offset: ((gradient_end / full_range) * 100).toFixed(0) + '%',
        color: '#E1000F',
      },
    ];
  }

  if (below_crit_range) {
    const gradient_start = below_crit_range.max - offset - y_extent[0];
    const gradient_end = below_crit_range.max + offset - y_extent[0];

    below_crit_offsets = [
      {
        offset: ((gradient_start / full_range) * 100).toFixed(0) + '%',
        color: '#E1000F',
      },
      {
        offset: ((gradient_end / full_range) * 100).toFixed(0) + '%',
        color: 'black',
      },
    ];
  }

  return [
    {
      offset: '-100%',
      color: below_crit_range ? '#E1000F' : 'black',
    },
    ...below_crit_offsets,
    ...above_crit_offsets,
    {
      offset: '100%',
      color: above_crit_range ? '#E1000F' : 'black',
    },
  ];
}
