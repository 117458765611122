import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MsalModule, MsalRedirectComponent } from '@azure/msal-angular';
import { LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzRadioModule } from 'ng-zorro-antd/radio';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzSwitchModule } from 'ng-zorro-antd/switch';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTreeViewModule } from 'ng-zorro-antd/tree-view';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { AntiAuthGuard } from '../cc-framework/guards/anti-auth.guard';
import { AuthGuard } from '../cc-framework/guards/auth.guard';
import { AuthenticationService } from '../cc-framework/services/authentication.service';
import { StorageService } from '../cc-framework/services/storage.service';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { AdditionalInfoComponent } from './controls/additional-info/additional-info.component';
import { AssetNavButtonComponent } from './controls/asset-nav-button/asset-nav-button.component';
import { BannerNotificationComponent } from './controls/banner-notification/banner-notification.component';
import { BreadcrumbsComponent } from './controls/breadcrumbs/breadcrumbs.component';
import { CardComponent } from './controls/card/card.component';
import { OverlayCardHeaderComponent } from './controls/card/overlay-card/overlay-card-header/overlay-card-header.component';
import { OverlayCardItemComponent } from './controls/card/overlay-card/overlay-card-item/overlay-card-item.component';
import { OverlayCardComponent } from './controls/card/overlay-card/overlay-card.component';
import { DateRangeSliderComponent } from './controls/date-range-slider/date-range-slider.component';
import { DesktopHeaderComponent } from './controls/desktop-header/desktop-header.component';
import { AboutDialogComponent } from './controls/dialog/about-dialog/about-dialog.component';
import { DialogComponent } from './controls/dialog/dialog.component';
import { HelpDialogComponent } from './controls/dialog/help-dialog/help-dialog.component';
import { DocumentationItemComponent } from './controls/documentation-wizard/documentation-item/documentation-item.component';
import { DocumentationWizardComponent } from './controls/documentation-wizard/documentation-wizard.component';
import { FilterComponent } from './controls/filter/filter.component';
import { FooterComponent } from './controls/footer/footer.component';
import { GraphComponent } from './controls/graph/graph.component';
import { HeaderComponent } from './controls/header/header.component';
import { HistoryItemComponent } from './controls/history-item/history-item.component';
import { ImprintComponent } from './controls/imprint/imprint.component';
import { InputLabelComponent } from './controls/input-label/input-label.component';
import { AssetOverviewMapComponent } from './controls/map/asset-overview-map/asset-overview-map.component';
import { MapComponent } from './controls/map/map.component';
import { MoreInfoComponent } from './controls/more-info/more-info.component';
import { NavButtonComponent } from './controls/nav-button/nav-button.component';
import { NotificationAlertComponent } from './controls/notification/notification-alert/notification-alert.component';
import { NotificationComponent } from './controls/notification/notification.component';
import { OverlayComponent } from './controls/overlay/overlay.component';
import { ProgressBarComponent } from './controls/progress-bar/progress-bar.component';
import { ScrollToTopComponent } from './controls/scroll-to-top/scroll-to-top.component';
import { SearchFilterComponent } from './controls/search-filter/search-filter.component';
import { SelectComponent } from './controls/select/select.component';
import { SensorInstructionComponent } from './controls/sensor-instruction/sensor-instruction.component';
import { SensorValueComponent } from './controls/sensor-value/sensor-value.component';
import { SidebarComponent } from './controls/sidebar/sidebar.component';
import { SpinnerComponent } from './controls/spinner/spinner.component';
import { SplashScreenComponent } from './controls/splash-screen/splash-screen.component';
import { TabBarItemComponent } from './controls/tab-bar/tab-bar-item/tab-bar-item.component';
import { TabBarComponent } from './controls/tab-bar/tab-bar.component';
import { TabMenuComponent } from './controls/tab-menu/tab-menu.component';
import { TelemetryDisplayComponent } from './controls/telemetry-display-prototype/telemetry-display.component';
import { ThumbnailComponent } from './controls/thumbnail/thumbnail.component';
import { TileComponent } from './controls/tile/tile.component';
import { HighlightDirective } from './directives/highlight.directive';
import { MobileGuard } from './guards/mobile.guard';
import { AdminApproveComponent } from './pages/admin/admin-approve.component';
import { AssetOverviewComponent } from './pages/asset-overview/asset-overview.component';
import { RepairAndReplaceComponent } from './pages/asset-overview/repair-and-replace/repair-and-replace.component';
import {
  RepairInstructionsComponent,
} from './pages/asset-overview/repair-and-replace/repair-instructions/repair-instructions.component';
import { SensorHealthPageComponent } from './pages/asset-overview/sensor-health-page/sensor-health-page.component';
import { SteamTrapAnalyticsComponent } from './pages/asset-overview/steam-trap-analytics/steam-trap-analytics.component';
import {
  AssetSensorHistoryComponent,
} from './pages/asset-overview/telemetry-page/asset-sensor-history/asset-sensor-history.component';
import { TelemetryPageComponent } from './pages/asset-overview/telemetry-page/telemetry-page.component';
import { ChangePwFormComponent } from './pages/change-pw/change-pw-form/change-pw-form.component';
import { ChangePwComponent } from './pages/change-pw/change-pw.component';
import { CookieConsentComponent } from './pages/cookie-consent/cookie-consent.component';
import { DataProtectionComponent } from './pages/data-protection/data-protection.component';
import { DisabledAssetsComponent } from './pages/disabled-assets/disabled-assets.component';
import { HomepageComponent } from './pages/homepage/homepage.component';
import { MapOverlayComponent } from './pages/homepage/map-overlay/map-overlay.component';
import { PlantListComponent } from './pages/homepage/plant-list/plant-list.component';
import { ImprintPageComponent } from './pages/imprint/imprint-page.component';
import { LegalImprintComponent } from './pages/legal-imprint/legal-imprint.component';
import { LoginComponent } from './pages/login/login.component';
import { NoteForUsResidentsComponent } from './pages/note-for-us-residents/note-for-us-residents.component';
import { NotificationsListComponent } from './pages/notifications-list/notifications-list.component';
import {
  PlantOverviewContentComponent,
} from './pages/plant-overview/plant-overview-content/plant-overview-content.component';
import { PlantOverviewComponent } from './pages/plant-overview/plant-overview.component';
import { ServiceConditionsComponent } from './pages/service-conditions/service-conditions.component';
import {
  EmailToggleComponent,
} from './pages/settings/settings-subpage/notifications-settings-subpage/notification-settings-components/email-toggle.component';
import {
  PushNotificationToggleComponent,
} from './pages/settings/settings-subpage/notifications-settings-subpage/notification-settings-components/push-notification-toggle.component';
import {
  NotificationsSettingsSubpageComponent,
} from './pages/settings/settings-subpage/notifications-settings-subpage/notifications-settings-subpage.component';
import { SettingsSubpageComponent } from './pages/settings/settings-subpage/settings-subpage.component';
import {
  TechnicalDocumentsSubpageComponent,
} from './pages/settings/settings-subpage/technical-documents-subpage/technical-documents-subpage.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { SignupComponent } from './pages/signup/signup.component';
import { TermsOfUseComponent } from './pages/terms-of-use/terms-of-use.component';
import { UsCaPrivacyRightsComponent } from './pages/us-ca-privacy-rights/us-ca-privacy-rights.component';
import { UsPrivacyRightsComponent } from './pages/us-privacy-rights/us-privacy-rights.component';
import { DashesIfEmptyPipe } from './pipes/dashes-if-empty.pipe';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { NameSlicePipe } from './pipes/name-slice.pipe';
import { UnitConversionPipe } from './pipes/unit-conversion.pipe';
import { AlertStatisticsService } from './services/alert-statistics.service';
import { BannerNotificationService } from './services/banner-notification.service';
import { DocumentationService } from './services/documentation.service';
import { ErrorHttpInterceptor } from './services/error-http-interceptor';
import { I18nService } from './services/i18n.service';
import { NotificationService } from './services/notification.service';
import { SettingsService } from './services/settings.service';
import { UiService } from './services/ui.service';

// import { UsCaPrivacyRightsComponent } from './pages/us-ca-privacy-rights/us-ca-privacy-rights.component';
// import { UsPrivacyRightsComponent } from './pages/us-privacy-rights/us-privacy-rights.component';
registerLocaleData(localeDe);

const appRoutes: Routes = [
  {
    path: 'login/forgotpw/:mail',
    component: LoginComponent,
    canActivate: [AntiAuthGuard],
    data: {
      animation: 'Login',
    },
  },
  {
    path: 'login/refauth/:token/:args',
    component: LoginComponent,
    canActivate: [AntiAuthGuard],
    data: {
      animation: 'Login',
    },
  },
  {
    path: 'login/refauth/:token',
    component: LoginComponent,
    canActivate: [AntiAuthGuard],
    data: {
      animation: 'Login',
    },
  },
  { path: 'login/us', redirectTo: '/login?country=us' },
  {
    path: 'login/:key',
    component: LoginComponent,
    canActivate: [AntiAuthGuard],
    data: {
      animation: 'Login',
    },
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AntiAuthGuard],
    data: {
      animation: 'Login',
    },
  },
  {
    path: 'imprint',
    component: ImprintPageComponent,
    canActivate: [AntiAuthGuard],
    data: {
      animation: 'LegalDocuments',
    },
  },
  {
    path: 'signup',
    component: SignupComponent,
    canActivate: [AntiAuthGuard],
    data: {},
  },
  {
    path: 'admin/approve-user/:token',
    component: AdminApproveComponent,
    canActivate: [AntiAuthGuard],
    data: {},
  },
  {
    path: 'changepw/:token',
    component: ChangePwComponent,
    canActivate: [AntiAuthGuard],
    data: {
      animation: 'Login',
    },
  },
  {
    path: 'home/:tab/:id',
    component: HomepageComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Homepage',
      tabIndex: 0,
      backBtn: false,
      routeReuse: true,
      reuseID: -1,
    },
  },
  {
    path: 'home/:tab',
    component: HomepageComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Homepage',
      tabIndex: 0,
      backBtn: false,
      routeReuse: true,
      reuseID: -1,
    },
  },
  {
    path: 'asset/:id/:sensorid',
    component: AssetOverviewComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'AssetOverview',
      tabIndex: 0,
      backBtn: true,
      routeReuse: true,
      reuseID: ':id',
    },
  },
  {
    path: 'repair/:id/:sensorid',
    component: RepairAndReplaceComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'AssetOverview',
      tabIndex: 0,
      backBtn: true,
      routeReuse: true,
      reuseID: ':id',
    },
  },
  {
    path: 'sensor-health/:id',
    component: SensorHealthPageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'SensorHealth', tabIndex: 0, backBtn: true },
  },
  {
    path: 'sensor-value/:id/:sensorid/:measurementid',
    component: TelemetryPageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'Telemetry', tabIndex: 0, backBtn: true },
  },
  {
    path: 'sensor-history/:id/:sensorid/:measurementid',
    component: AssetSensorHistoryComponent,
    canActivate: [AuthGuard],
    data: { animation: 'SensorHistory', tabIndex: 0, backBtn: true },
  },
  {
    path: 'plant/:id',
    component: PlantOverviewComponent,
    canActivate: [AuthGuard],
    data: { animation: 'PlantOverview', tabIndex: 0, backBtn: true },
  },
  {
    path: 'notifications',
    component: NotificationsListComponent,
    canActivate: [AuthGuard],
    data: { animation: 'Notifications', tabIndex: 1, backBtn: false },
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard],
    data: { animation: 'Settings', tabIndex: 2, backBtn: false },
  },
  {
    path: 'settings/profile',
    component: SettingsSubpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'SettingsSub', tabIndex: 2, backBtn: true },
  },
  {
    path: 'settings/change-password',
    component: SettingsSubpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'SettingsSub', tabIndex: 2, backBtn: true },
  },
  {
    path: 'settings/language',
    component: SettingsSubpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'SettingsSub', tabIndex: 2, backBtn: true },
  },
  {
    path: 'settings/metric',
    component: SettingsSubpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'SettingsSub', tabIndex: 2, backBtn: true },
  },
  {
    path: 'settings/appearance',
    component: SettingsSubpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'SettingsSub', tabIndex: 2, backBtn: true },
  },
  {
    path: 'settings/notifications',
    component: SettingsSubpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'SettingsSub', tabIndex: 2, backBtn: true },
  },
  {
    path: 'settings/technical_documents',
    component: SettingsSubpageComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'SettingsSub',
      tabIndex: 2,
      backBtn: true,
      appHeader: true,
    },
  },
  {
    path: 'settings/notifications/flange',
    component: SettingsSubpageComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'SettingsSubSub',
      tabIndex: 2,
      backBtn: true,
      appHeader: true,
    },
  },
  {
    path: 'settings/notifications/pipe',
    component: SettingsSubpageComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'SettingsSubSub',
      tabIndex: 2,
      backBtn: true,
      appHeader: true,
    },
  },
  {
    path: 'settings/notifications/steamtrap',
    component: SettingsSubpageComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'SettingsSubSub',
      tabIndex: 2,
      backBtn: true,
      appHeader: true,
    },
  },
  {
    path: 'settings/technical_documents/:page',
    component: SettingsSubpageComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'SettingsSubSub',
      tabIndex: 2,
      backBtn: true,
      appHeader: true,
    },
  },
  {
    path: 'settings/help',
    component: SettingsSubpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'SettingsSub', tabIndex: 2, backBtn: true },
  },
  {
    path: 'settings/help/about',
    component: SettingsSubpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'SettingsSubSub', tabIndex: 2, backBtn: true },
  },
  {
    path: 'settings/help/faq',
    component: SettingsSubpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'SettingsSubSub', tabIndex: 2, backBtn: true },
  },
  {
    path: 'settings/terms',
    component: SettingsSubpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'SettingsSub', tabIndex: 2, backBtn: true },
  },
  {
    path: 'settings/terms/termsofservice',
    component: SettingsSubpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'SettingsSubSub', tabIndex: 2, backBtn: true },
  },
  {
    path: 'settings/terms/privacypolicy',
    component: SettingsSubpageComponent,
    canActivate: [AuthGuard],
    data: { animation: 'SettingsSubSub', tabIndex: 2, backBtn: true },
  },
  {
    path: 'filter',
    component: FilterComponent,
    canActivate: [AuthGuard],
    data: { transparentHeader: true },
  },
  {
    path: 'terms-of-use',
    component: TermsOfUseComponent,
    canActivate: [AntiAuthGuard],
    data: {
      animation: 'LegalDocuments',
      tabIndex: 0,
      backBtn: true,
      appHeader: true,
    },
  },
  {
    path: 'service-conditions',
    component: ServiceConditionsComponent,
    canActivate: [AntiAuthGuard],
    data: {
      animation: 'LegalDocuments',
      tabIndex: 0,
      backBtn: true,
      appHeader: true,
    },
  },
  {
    path: 'us/terms-of-use',
    component: TermsOfUseComponent,
    canActivate: [AntiAuthGuard],
    data: {
      animation: 'LegalDocuments',
      tabIndex: 0,
      backBtn: true,
      appHeader: true,
    },
  },
  {
    path: 'settings/terms-of-use',
    component: SettingsSubpageComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'SettingsSub',
      tabIndex: 2,
      backBtn: true,
      appHeader: true,
    },
  },
  {
    path: 'note-for-us-residents',
    component: NoteForUsResidentsComponent,
    canActivate: [AntiAuthGuard],
    data: {
      animation: 'LegalDocuments',
      tabIndex: 0,
      backBtn: true,
      appHeader: true,
    },
  },
  {
    path: 'cookie-consent',
    component: CookieConsentComponent,
    canActivate: [AntiAuthGuard],
    data: {
      animation: 'LegalDocuments',
      tabIndex: 0,
      backBtn: true,
      appHeader: true,
    },
  },
  {
    path: 'settings/note-for-us-residents',
    component: SettingsSubpageComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'SettingsSub',
      tabIndex: 2,
      backBtn: true,
      appHeader: true,
    },
  },
  {
    path: 'settings/data-protection',
    component: SettingsSubpageComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'SettingsSub',
      tabIndex: 2,
      backBtn: true,
      appHeader: true,
    },
  },
  {
    path: 'settings/service-conditions',
    component: SettingsSubpageComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'SettingsSub',
      tabIndex: 2,
      backBtn: true,
      appHeader: true,
    },
  },

  {
    path: 'data-protection',
    component: DataProtectionComponent,
    canActivate: [AntiAuthGuard],
    data: {
      animation: 'LegalDocuments',
      tabIndex: 0,
      backBtn: true,
      appHeader: true,
    },
  },
  {
    path: 'us/data-protection',
    component: DataProtectionComponent,
    canActivate: [AntiAuthGuard],
    data: {
      animation: 'LegalDocuments',
      tabIndex: 0,
      backBtn: true,
      appHeader: true,
    },
  },

  {
    path: 'legal-imprint',
    component: LegalImprintComponent,
    canActivate: [AntiAuthGuard],
    data: {
      animation: 'LegalDocuments',
      tabIndex: 0,
      backBtn: true,
      appHeader: true,
    },
  },
  {
    path: 'settings/imprint',
    component: SettingsSubpageComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'SettingsSub',
      tabIndex: 0,
      backBtn: true,
      appHeader: true,
    },
  },
  {
    path: 'us/privacy-policy',
    component: UsPrivacyRightsComponent,
    canActivate: [AntiAuthGuard],
    data: {
      animation: 'LegalDocuments',
      tabIndex: 0,
      backBtn: true,
      appHeader: true,
    },
  },
  {
    path: 'us/ca-privacy-policy',
    component: UsCaPrivacyRightsComponent,
    canActivate: [AntiAuthGuard],
    data: {
      animation: 'LegalDocuments',
      tabIndex: 0,
      backBtn: true,
      appHeader: true,
    },
  },
  {
    path: 'disabledassets/:id',
    component: DisabledAssetsComponent,
    canActivate: [AuthGuard],
    data: { animation: 'disabledassets', tabIndex: 0, backBtn: true },
  },
  {
    path: '**',
    redirectTo: '/home/map',
    pathMatch: 'full',
    // canActivate: [AuthGuard, MobileGuard],
  },
  // final fallback route happens in MobileGuard
];

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  declarations: [
    AppComponent,
    DashesIfEmptyPipe,
    HelpDialogComponent,
    AboutDialogComponent,
    DialogComponent,
    DateAgoPipe,
    HighlightDirective,
    DateRangeSliderComponent,
    LoginComponent,
    HomepageComponent,
    AssetOverviewComponent,
    AssetOverviewMapComponent,
    SensorHealthPageComponent,
    TelemetryPageComponent,
    AssetSensorHistoryComponent,
    PlantOverviewComponent,
    SettingsComponent,
    SettingsSubpageComponent,
    SpinnerComponent,
    NotificationsListComponent,
    HeaderComponent,
    FooterComponent,
    TabBarComponent,
    TabBarItemComponent,
    InputLabelComponent,
    BreadcrumbsComponent,
    SearchFilterComponent,
    CardComponent,
    SidebarComponent,
    OverlayCardComponent,
    OverlayCardItemComponent,
    OverlayCardHeaderComponent,
    DocumentationItemComponent,
    DocumentationWizardComponent,
    SensorInstructionComponent,
    NotificationComponent,
    NotificationAlertComponent,
    NavButtonComponent,
    ThumbnailComponent,
    AssetNavButtonComponent,
    MoreInfoComponent,
    ProgressBarComponent,
    SensorValueComponent,
    TabMenuComponent,
    TileComponent,
    HistoryItemComponent,
    MapComponent,
    OverlayComponent,
    GraphComponent,
    FilterComponent,
    DesktopHeaderComponent,
    SplashScreenComponent,
    PlantListComponent,
    PlantOverviewContentComponent,
    MapOverlayComponent,
    UnitConversionPipe,
    ChangePwComponent,
    ChangePwFormComponent,
    SteamTrapAnalyticsComponent,
    TelemetryDisplayComponent,
    ImprintComponent,
    ImprintPageComponent,
    RepairAndReplaceComponent,
    RepairInstructionsComponent,
    AdditionalInfoComponent,
    TechnicalDocumentsSubpageComponent,
    SelectComponent,
    NotificationsSettingsSubpageComponent,
    EmailToggleComponent,
    PushNotificationToggleComponent,
    DisabledAssetsComponent,
    LegalImprintComponent,
    NoteForUsResidentsComponent,
    DataProtectionComponent,
    TermsOfUseComponent,
    ServiceConditionsComponent,
    SignupComponent,
    AdminApproveComponent,
    NameSlicePipe,
    ScrollToTopComponent,
    UsCaPrivacyRightsComponent,
    UsPrivacyRightsComponent,
    BannerNotificationComponent,
  ],
  entryComponents: [HelpDialogComponent, AboutDialogComponent], // dynamically generated components
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true, relativeLinkResolution: 'legacy' } // <-- debugging purposes only
      // <-- debugging purposes only
    ),
    ScrollingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgxExtendedPdfViewerModule,
    // ng-Zorro:
    NzAlertModule,
    NzAvatarModule,
    NzLayoutModule,
    NzMenuModule,
    NzPageHeaderModule,
    NzTabsModule,
    NzIconModule,
    NzInputModule,
    NzImageModule,
    NzListModule,
    NzButtonModule,
    NzGridModule,
    NzCascaderModule,
    NzCardModule,
    NzSelectModule,
    NzDatePickerModule,
    NzInputNumberModule,
    NzDescriptionsModule,
    NzBadgeModule,
    NzBreadCrumbModule,
    NzSwitchModule,
    NzRadioModule,
    NzModalModule,
    NzCollapseModule,
    NzSpinModule,
    NzTreeViewModule,
    NzProgressModule,
    NzPaginationModule,
    NgScrollbarModule,
    ServiceWorkerModule.register('custom-service-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    MsalModule.forRoot(
      new PublicClientApplication({
        auth: {
          clientId: environment.msalClientID, // Application (client) ID from the app registration
          authority:
            environment.msalIssuerService + environment.msalIssuerServiceTenant, // The Azure cloud instance and the app's sign-in audience (tenant ID, common, organizations, or consumers)
          redirectUri: environment.redirect_uri, // This is your redirect URI
          navigateToLoginRequestUrl: false,
        },
        cache: {
          cacheLocation: 'localStorage',
          storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
        },
        system: {
          loggerOptions: {
            loggerCallback: (logLevel, message, containsPii) => {
              console.log('msal log', message);
            },
            logLevel: LogLevel.Verbose,
            piiLoggingEnabled: false,
          },
        },
      }),
      null,
      null
    ),
  ],
  providers: [
    AuthenticationService,
    StorageService,
    DocumentationService,
    AlertStatisticsService,
    BannerNotificationService,
    SettingsService,
    NotificationService,
    I18nService,
    UiService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorHttpInterceptor,
      multi: true,
    },
    AntiAuthGuard,
    MobileGuard,
    {
      provide: LOCALE_ID,
      deps: [SettingsService], // some service handling global settings
      useFactory: (settingsService) => settingsService.getLocaleID(), // returns locale string
    },
    {
      provide: NZ_I18N,
      deps: [SettingsService], // some service handling global settings
      useFactory: (settingsService) => settingsService.getNZI18n(), // returns locale string
    },
    // { provide: LOCALE_ID, useValue: 'en-EN' },
    // { provide: NZ_I18N, useValue: en_US }, // todo: localization, get from config
    //  { provide: RouteReuseStrategy, useClass: CustomReuseStrategy },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
