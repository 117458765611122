<app-splash-screen *ngIf="loading || loadingError" [error]="loadingError"></app-splash-screen>
<ng-container [class.remove]="loading || loadingError">
  <span *ngIf="environmentInstanceName !== 'PROD'" class="test">{{environmentInstanceName}}</span>
  <app-desktop-header *ngIf="isAuth && isDesktop"></app-desktop-header>
  <div class="scrollable" [class.desktop]="isDesktop" #scrollContent>
    <!--<app-header [transparent]="headerTransparent(outlet)" ></app-header>-->
    <div id="main" [@routeAnimations]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet" (activate)="onActivate(outlet)"></router-outlet>
    </div>
  </div>
  <app-footer *ngIf="isAuth && !isDesktop"></app-footer>
</ng-container>