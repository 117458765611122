<div class="filter" [class.filter-sidebar]="isDesktop">
  <div *ngIf="isDesktop" class="filter-title">
    <i nz-icon nzType="icons:filter" class="svg-icon"></i>
    <h4>{{ i18n.String("filters") }}</h4>
    <button *ngIf="isFiltered()" class="clear-btn" aria-label="Clear Filter" (click)="clearAllFilters($event)">
      <i nz-icon nzType="icons:exit" class="svg-icon"></i>
      <span class="body-bold">{{ i18n.String("clear_filter") }}</span>
    </button>
  </div>

  <div class="filter-inner-content" [class.mobile]="IsMobile" [class.tablet]="IsTablet">
    <div class="select-container">
      <label for="status" class="body-bold select-label">{{
        i18n.String("status")
        }}</label>
      <div class="select-inner-container">
        <nz-select #filterStatus name="status" [(ngModel)]="Status" [class.selected]="Status > 0"
          [nzCustomTemplate]="filterStatusTemplate" [nzShowArrow]="false">
          <nz-option [nzValue]="0" [nzLabel]="i18n.string('all_status')"></nz-option>
          <nz-option [nzDisabled]="!hasWorking" [nzValue]="1" [nzLabel]="i18n.string('working')"></nz-option>
          <nz-option [nzDisabled]="!hasWarning" [nzValue]="2" [nzLabel]="i18n.string('warning')"></nz-option>
          <nz-option [nzDisabled]="!hasCritical" [nzValue]="3" [nzLabel]="i18n.string('critical')"></nz-option>
          <nz-option [nzDisabled]="!hasInactive" [nzValue]="4" [nzLabel]="i18n.string('inactive')"></nz-option>
        </nz-select>

        <ng-template #filterStatusTemplate let-selected>
          <div class="customSelectBox">
            <span>{{ selected.nzLabel }}</span>
            <i nz-icon [nzType]="
                filterStatus.nzOpen
                  ? 'icons:dropup-arrow'
                  : 'icons:dropdown-arrow'
              " class="customSelectIcon fillblackcoral"></i>
          </div>
        </ng-template>
      </div>
    </div>

    <div class="select-container" *ngIf="hasFlanges || hasPipes || hasTanks || hasValves || hasRotatingEquipment">
      <label class="body-bold">{{ i18n.String("equipment_type") }}</label>
      <div class="asset-type-container">
        <button class="assets" [class.active]="filter.Flange" (click)="filter.Flange = !filter.Flange"
          *ngIf="hasFlanges">
          <i nz-icon nzType="icons:flange" class="svg-icon filter-icon" [class.fillwhite]="filter.Flange"></i>
          <p class="icon-text">{{ i18n.String("flange") }}</p>
          <i nz-icon *ngIf="!filter.Flange" nzType="icons:checkbox-empty" class="svg-icon filter-check-icon"></i>
          <i nz-icon *ngIf="filter.Flange" nzType="icons:status-check" class="svg-icon filter-check-icon fillwhite"></i>
        </button>
        <button class="assets" [class.active]="filter.Tank" (click)="filter.Tank = !filter.Tank" *ngIf="hasTanks">
          <i nz-icon nzType="icons:tank" class="svg-icon tank-icon" [class.fillwhite]="filter.Tank"></i>
          <p class="icon-text">{{ i18n.String("tank") }}</p>
          <i nz-icon *ngIf="!filter.Tank" nzType="icons:checkbox-empty" class="svg-icon filter-check-icon"></i>
          <i nz-icon *ngIf="filter.Tank" nzType="icons:status-check" class="svg-icon filter-check-icon fillwhite"></i>
        </button>
        <button class="assets" [class.active]="filter.Pipe" (click)="filter.Pipe = !filter.Pipe" *ngIf="hasPipes">
          <i nz-icon nzType="icons:pipe" class="svg-icon pipe-icon" [class.fillwhite]="filter.Pipe"></i>
          <p class="icon-text">{{ i18n.String("pipe") }}</p>
          <i nz-icon *ngIf="!filter.Pipe" nzType="icons:checkbox-empty" class="svg-icon filter-check-icon"></i>
          <i nz-icon *ngIf="filter.Pipe" nzType="icons:status-check" class="svg-icon filter-check-icon fillwhite"></i>
        </button>
        <button class="assets" [class.active]="filter.Valve" (click)="filter.Valve = !filter.Valve" *ngIf="hasValves">
          <i nz-icon nzType="icons:valve" class="svg-icon valve-icon" [class.fillwhite]="filter.Valve"></i>
          <p class="icon-text">{{ i18n.String("valve") }}</p>
          <i nz-icon *ngIf="!filter.Valve" nzType="icons:checkbox-empty" class="svg-icon filter-check-icon"></i>
          <i nz-icon *ngIf="filter.Valve" nzType="icons:status-check" class="svg-icon filter-check-icon fillwhite"></i>
        </button>
        <button class="assets" [class.active]="filter.SteamTrap" (click)="filter.SteamTrap = !filter.SteamTrap"
          *ngIf="hasSteamTraps">
          <i nz-icon nzType="icons:steamtrap" class="svg-icon steamtrap-icon" [class.fillwhite]="filter.SteamTrap"></i>
          <p class="icon-text" [class.active]="filter.SteamTrap">
            {{ i18n.String("steamtrap") }}
          </p>
          <i nz-icon *ngIf="!filter.SteamTrap" nzType="icons:checkbox-empty" class="svg-icon filter-check-icon"></i>
          <i nz-icon *ngIf="filter.SteamTrap" nzType="icons:status-check"
            class="svg-icon filter-check-icon fillwhite"></i>
        </button>
        <!-- commenting it for 27 June 2024 pre-prod and prod deplyment
        <button class="assets" [class.active]="filter.RotatingEquipment"
          (click)="filter.RotatingEquipment = !filter.RotatingEquipment" *ngIf="hasRotatingEquipment">
          <i nz-icon nzType="icons:rotatingEqu" class="svg-icon rotatingEqu-icon"
            [class.fillwhite]="filter.RotatingEquipment"></i>
          <p class="icon-text" [class.active]="filter.RotatingEquipment">{{ i18n.String("rotatingEqu") }}</p>
          <i nz-icon *ngIf="!filter.RotatingEquipment" nzType="icons:checkbox-empty"
            class="svg-icon filter-check-icon"></i>
          <i nz-icon *ngIf="filter.RotatingEquipment" nzType="icons:status-check"
            class="svg-icon filter-check-icon fillwhite"></i>
        </button>
      -->
      </div>
    </div>

    <div class="select-container customer" *ngIf="isSuperUser && !plantsFilter">
      <label class="body-bold select-label">{{
        i18n.String("customer")
        }}</label>
      <nz-select #filterCustomer [(ngModel)]="Customer" [compareWith]="fnCustomerCompare"
        [class.selected]="Customer !== 'null'" [disabled]="CustomerSelectDisabled"
        [nzCustomTemplate]="filterCustomerTemplate" [nzShowArrow]="false">
        <nz-option nzValue="null" [nzLabel]="i18n.String('all_customers')"></nz-option>
        <nz-option [nzValue]="customer" *ngFor="let customer of customerList" [nzLabel]="customer">
        </nz-option>
      </nz-select>
      <ng-template #filterCustomerTemplate let-selected>
        <div class="customSelectBox">
          <span>{{ selected.nzLabel }}</span>
          <i nz-icon [nzType]="
              filterCustomer.nzOpen
                ? 'icons:dropup-arrow'
                : 'icons:dropdown-arrow'
            " class="customSelectIcon fillblackcoral"></i>
        </div>
      </ng-template>
    </div>

    <div class="select-container location">
      <label class="body-bold select-label">{{
        i18n.String("location")
        }}</label>
      <app-breadcrumbs [array]="SelectionPreset" *ngIf="!!SelectionPreset"></app-breadcrumbs>
      <ng-container *ngFor="let selection of filterSelections">
        <div class="select-inner-container-location" *ngIf="selection.name != 'Equipment'">
          <nz-select #filterLocation [(ngModel)]="selection.Selected" [class.selected]="selection.Selected !== empty"
            [class.remove]="selection.disabled" [compareWith]="fnLocationCompare" [disabled]="selection.disabled"
            [nzCustomTemplate]="filterLocationTemplate" [nzShowArrow]="false">
            <nz-option [nzValue]="empty" [nzLabel]="selection.name"></nz-option>
            <nz-option [nzValue]="value" *ngFor="let value of selection.values"
              [nzLabel]="i18n.Parse(value.objectName)">
            </nz-option>
          </nz-select>

          <ng-template #filterLocationTemplate let-selected>
            <div class="customSelectBox">
              <span>{{ selected.nzLabel }}</span>
              <i nz-icon [nzType]="
                  filterLocation.nzOpen
                    ? 'icons:dropup-arrow'
                    : 'icons:dropdown-arrow'
                " class="customSelectIcon fillblackcoral"></i>
            </div>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="footer">
    <app-nav-button class="filter-button drop-shadow body-bold" (click)="onSubmit()" primary="true"
      [class.active-filter]="AssetCount > 0">
      <nz-badge *ngIf="AssetCount > 0 && filterCount > 0" [nzCount]="AssetCount" nzStandalone
        [nzStyle]="{ backgroundColor: 'white', color: '#5F6973' }"></nz-badge>
      <span>{{ i18n.string("show_results") }}</span>
    </app-nav-button>
    <button (click)="clearAllFilters($event)">
      {{ i18n.string("clear_filter") }}
    </button>
  </div>
</div>